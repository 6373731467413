<template>
    <div class="roadFront">
         <div class="loading" v-if="loading" style="width: 183px;top: 29px;">
            <i class="d-center">
                <img src="@/assets/mapimages/new/map-loading.gif" alt />
            </i>
            <span>正在导出，请稍等！！！</span>
        </div>
        <el-container class="ova-y">
            <el-main class="p0">
                <el-row>
                    <el-col :span="24">
                        <div class="box" ref="init">
                            <div class="box-content">
                                <el-form :inline="true" class="form-inline formBox">
                                    <el-form-item label="行政区划" >
                                        <el-cascader :key="parseInt(Math.random()*10000)" style="width: 165px" size="medium" v-model="region" :options="regions" :props="{ checkStrictly: true, expandTrigger: 'hover' }" clearable ></el-cascader>
                                    </el-form-item>
                                    <el-form-item label="项目名称">
                                        <el-input style="width: 165px" size="medium" v-model="xmmc" placeholder="请输入" clearable></el-input>
                                    </el-form-item>
                                    <el-form-item label="路线性质">
                                        <el-cascader size="medium" style="width: 165px" v-model="checkedXmlx" :options="xmlxList" placeholder="请选择" :props="{ expandTrigger: 'hover', multiple: true }" collapse-tags clearable>
                                        </el-cascader>
                                    </el-form-item>
                                    <el-form-item label="建设性质">
                                        <el-cascader size="medium" style="width: 165px" v-model="checkedJsxz" :options="jsxzList" placeholder="请选择" :props="{ expandTrigger: 'hover', multiple: true }" collapse-tags clearable>
                                        </el-cascader>
                                    </el-form-item>
                                    <el-form-item label="审批流程">
                                        <el-cascader size="medium" style="width: 165px" v-model="checkedFlow" :options="flowList" placeholder="请选择" :props="{ expandTrigger: 'hover', multiple: true }" collapse-tags clearable>
                                        </el-cascader>
                                    </el-form-item>
                                    <el-form-item label="填报批次">
                                        <el-cascader
                                                v-model="treeVal"
                                                :options="treeData"
                                                :props="defaultParams"
                                                @change="changePc"
                                                clearable></el-cascader>
                                    </el-form-item>
                                    <el-form-item>
                                        <el-button type="primary"  style="margin-top:19px"  size="medium" icon="el-icon-search" @click="currentPage = 1;search();">查询</el-button>
                                        <el-button type="primary" size="medium" plain @click="clearCondition();search();" icon="el-icon-delete">删除</el-button>
                                        <el-button type="text" @click="show3 = !show3" icon="el-icon-arrow-down">{{show3 ? '收起' : '展开'}}</el-button>

                                    </el-form-item>

                                </el-form>
                            </div>
                            <el-collapse-transition>
                            <div  v-show="show3" class="box-content" style="background: #fff">
                                <div class="tj">

                                    <div class="total-item total-item-1">
                                        <img src="../../assets/icon/tj1.png" alt="">
                                        <div class="t">
                                            <h5>项目数量<small>（个）</small></h5>
                                            <b class="date-num">{{tjArr.fillStatistics?tjArr.fillStatistics.count:0}}</b>
                                        </div>
                                    </div>

                                    <!-- <div class="total-item total-item-5">
                                        <img src="../../assets/icon/tj6.png" alt="">
                                        <div class="t">
                                            <h5>待提交<small>（个）</small></h5>
                                            <b class="date-num">{{tjArr.fillStatistics?tjArr.fillStatistics.ysh:0}}</b>
                                        </div>
                                    </div> -->

                                    <div class="total-item total-item-5">
                                        <img src="../../assets/icon/tj6.png" alt="">
                                        <div class="t">
                                            <h5>待审核<small>（个）</small></h5>
                                            <b class="date-num">{{tjArr.fillStatistics?tjArr.fillStatistics.ytj:0}}</b>
                                        </div>
                                    </div>

                                    <div class="total-item total-item-4">
                                        <img src="../../assets/icon/tj7.png" alt="">
                                        <div class="t">
                                            <h5>审核通过<small>（个）</small></h5>
                                            <b class="date-num">{{tjArr.fillStatistics?tjArr.fillStatistics.ysh:0}}</b>
                                        </div>
                                    </div>


                                    <div class="total-item total-item-2">
                                        <img src="../../assets/icon/tj8.png" alt="">
                                        <div class="t">
                                            <h5>驳回<small>（个）</small></h5>
                                            <b class="date-num">{{tjArr.fillStatistics?tjArr.fillStatistics.wtg:0}}</b>
                                        </div>
                                    </div>
                                    <div class="total-item total-item-6">
                                        <img src="../../assets/icon/tj1.png" alt="">
                                        <div class="t">
                                            <h5>建设规模-公路<small>（公里）</small></h5>
                                            <b class="date-num">{{tjArr.fillStatistics?tjArr.fillStatistics.jsgm:0}}</b>
                                        </div>
                                    </div>


                                    <div class="total-item total-item-7">
                                        <img src="../../assets/icon/tj4.png" alt="">
                                        <div class="t">
                                            <h5>当年总投资<small>（万元）</small></h5>
                                            <b class="date-num">{{tjArr.fillStatistics?tjArr.fillStatistics.ztz:0}}</b>
                                        </div>
                                    </div>


                                    <div class="total-item total-item-8">
                                        <img src="../../assets/icon/tj5.png" alt="">
                                        <div class="t">
                                            <h5>当年中央车购税<small>（万元）</small></h5>
                                            <b class="date-num">{{tjArr.fillStatistics?tjArr.fillStatistics.zytz:0}}</b>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            </el-collapse-transition>
                        </div>
                    </el-col>
                </el-row>
                <el-row class="plr12">
                    <el-col :span="24">
                        <div class="box">
                            <div class="box-title">
                                <span class="fright">
                                    <el-button @click="exportData(2)" type="primary" size="mini" round icon="el-icon-upload2">通过项目导出</el-button>
                                    <span class="newre" v-if="isAdmin">
                                        <el-cascader @change="changeAddressCity" style="width:122px;line-height: 28px;" collapse-tags size="medium" v-model="expParamsCity" :options="regions2" :props="{ multiple: true}" clearable ></el-cascader>
                                        <el-button @click="exportData(3)" type="primary" size="mini" round >分地市导出</el-button>
                                    </span>
                                    <el-button @click="exportData(1)" type="primary" size="mini" round icon="el-icon-upload2">导出</el-button>
                                    <el-button @click="addRoad" type="primary" size="mini" round icon="el-icon-plus">填报</el-button>
                                </span>
                               <div style="display: flex">
                                   <h3 class="tit">公路列表</h3>
<!--                                   <div style="width: 190px">-->
<!--                                       <el-tree :data="treeData"-->
<!--                                                ref="tree"-->
<!--                                                node-key="id"-->
<!--                                                show-checkbox-->
<!--                                                :props="defaultProps"-->
<!--                                                @node-click="handleNodeClick"-->
<!--                                                @check="handleCheckChange"></el-tree>-->
<!--                                   </div>-->
                               </div>
                            </div>
                             <el-tabs v-model="activeRoad" @tab-click="changeRoad" style="padding:0 15px;">
                                <el-tab-pane label="高速公路" name="高速公路"></el-tab-pane>
                                <el-tab-pane label="普通国道" name="普通国道"></el-tab-pane>
                                <el-tab-pane label="普通省道" name="普通省道"></el-tab-pane>
                                <el-tab-pane label="G331及质量提升工程" name="G331及质量提升工程"></el-tab-pane>
                            </el-tabs>

                            <div class="box-content" style="padding-top: 0;">
                                <div>
                                    <div>
                                        <el-table  @selection-change="handleSelectionChange"  @sort-change="changeSort" @row-dblclick="openDetailsEdit" ref="table" :data="tableData" size="small " :height="tableHeight" border  style="width: 100%">
                                            <el-table-column
                                                    type="selection"
                                                    width="55">
                                            </el-table-column>
                                            <el-table-column fixed prop="" align="center" :width="180" label="操作">
                                                <template slot-scope="scope">
                                                    <!--                                            <span class="newicon iconsyes" @click="showMaps(scope.row)"><i class="el-icon-location icons iconsyes"></i>定位</span>-->
                                                    <span class="newicon iconsyes" @click="openDetailsEdit(scope.row)"><i class="el-icon-edit-outline icons iconsyes"></i>详情</span>
<!--                                                    <span class="newicon iconsyes" @click="deleted(scope.row)"><i class="el-icon-edit-outline icons iconsyes"></i>删除</span>-->
                                                    <span class="newicon iconsyes" v-if="scope.row.IsShowAudit" @click="checkPro(scope.row)"><i class="el-icon-edit-outline icons iconsyes"></i>审核</span>
                                                    <span class="newicon iconsyes" v-if="scope.row.IsShowSave" @click="checksB(scope.row)"><i class="el-icon-edit-outline icons iconsyes"></i>上报</span>
                                                    <!-- <el-popconfirm title="是否确认上报?" @confirm="checksB(scope.row)">
                                                        <template #reference>
                                                            <span class="newicon iconsyes" v-if="scope.row.IsShowSave"><i class="el-icon-edit-outline icons iconsyes"></i>上报</span>
                                                        </template>
                                                    </el-popconfirm> -->
                                                </template>
                                            </el-table-column>
                                            <el-table-column prop="index"  label="序号"  align="center" type="index" :width="50" :index="1+30*(currentPage-1)"></el-table-column>
                                            <el-table-column prop="XLXZ"  align="center" label="流程状态" :width="150">
                                                <template slot-scope="scope">
                                                    <div v-if="scope.row.FlowState == 0"><el-tag>待提交</el-tag></div>
                                                    <el-tooltip  :content="scope.row.Remarks" placement="top" v-if="scope.row.FlowState == -1 || scope.row.FlowState == -2 || scope.row.FlowState == -3">
                                                        <div v-if="scope.row.FlowState == -1"><el-tag type="danger">市级驳回 <i class="el-icon-info"></i></el-tag></div>
                                                        <div v-if="scope.row.FlowState == -2"><el-tag type="danger">省交通厅驳回 <i class="el-icon-info"></i></el-tag></div>
                                                        <div v-if="scope.row.FlowState == -3"><el-tag type="danger">报部审核驳回 <i class="el-icon-info"></i></el-tag></div>
                                                    </el-tooltip>
                                                    <div v-if="scope.row.FlowState == 1"><el-tag>市级待审核</el-tag></div>
                                                    <div v-if="scope.row.FlowState == 2"><el-tag>省交通厅待审核</el-tag></div>
                                                    <div v-if="scope.row.FlowState == 3"><el-tag >报部待审核</el-tag></div>
                                                    <div v-if="scope.row.FlowState == 4" ><el-tag type="success">审核通过</el-tag></div>
                                                </template>
                                            </el-table-column>
                                            <el-table-column prop="IsUploadConfirmFile"  align="center" label="是否上传确认文件" :width="150">
                                                <template slot-scope="scope">
                                                    <div v-if="scope.row.IsUploadConfirmFile"><el-tag >✅</el-tag></div>
                                                    <div v-else><el-tag >❌</el-tag></div>
                                                </template>
                                            </el-table-column>
                                            <el-table-column prop="SZS"  label="所在地市" :width="100" sortable show-overflow-tooltip>
                                                <template slot-scope="scope">
                                                    {{unique(scope.row.SZS)}}
                                                </template>
                                            </el-table-column>
                                            <el-table-column prop="SZX" label="所属县（市）" :width="120" sortable show-overflow-tooltip>
                                                <template slot-scope="scope">
                                                    {{unique1(scope.row.SZX)}}
                                                </template>
                                            </el-table-column>
                                            <el-table-column prop="XMMC" label="项目名称" align="center" show-overflow-tooltip :width="320"></el-table-column>
<!--                                            <el-table-column prop="QQMC"  align="center" label="对应前期项目名称" show-overflow-tooltip :width="320" ></el-table-column>-->
<!--                                            <el-table-column prop="XLXZ"  align="center" label="路线性质" show-overflow-tooltip  ></el-table-column>-->
                                            <el-table-column prop="JSXZ"  align="center" label="建设性质" show-overflow-tooltip ></el-table-column>
<!--                                            <el-table-column label="建设规模" :width="150"  align="center">-->
<!--                                                <el-table-column prop="JSGMHJ" label="合计(公里)" width="90" align="right">-->
<!--                                                    <template slot-scope="scope">-->
<!--                                                        {{scope.row.JSGMHJ ? scope.row.JSGMHJ.toFixed(2) : ''}}-->
<!--                                                    </template>-->
<!--                                                </el-table-column>-->
<!--                                                <el-table-column prop="JSGMGS" label="高速(公里)" width="90" align="right">-->
<!--                                                    <template slot-scope="scope">-->
<!--                                                        {{scope.row.JSGMGS ? scope.row.JSGMGS.toFixed(2) : ''}}-->
<!--                                                    </template>-->
<!--                                                </el-table-column>-->
<!--                                                <el-table-column prop="JSGMYJ" label="一级(公里)" width="90" align="right">-->
<!--                                                    <template slot-scope="scope">-->
<!--                                                        {{scope.row.JSGMYJ ? scope.row.JSGMYJ.toFixed(2) : ''}}-->
<!--                                                    </template>-->
<!--                                                </el-table-column>-->
<!--                                                <el-table-column prop="JSGMEJ" label="二级(公里)" width="90" align="right">-->
<!--                                                    <template slot-scope="scope">-->
<!--                                                        {{scope.row.JSGMEJ ? scope.row.JSGMEJ.toFixed(2) : ''}}-->
<!--                                                    </template>-->
<!--                                                </el-table-column>-->
<!--                                                <el-table-column prop="JSGMSJ" label="三级(公里)" width="90" align="right">-->
<!--                                                    <template slot-scope="scope">-->
<!--                                                        {{scope.row.JSGMSJ ? scope.row.JSGMSJ.toFixed(2) : ''}}-->
<!--                                                    </template>-->
<!--                                                </el-table-column>-->
<!--                                                <el-table-column prop="JSGMSJ" label="四级(公里)" width="90" align="right">-->
<!--                                                    <template slot-scope="scope">-->
<!--                                                        {{scope.row.JSGMSI ? scope.row.JSGMSI.toFixed(2) : ''}}-->
<!--                                                    </template>-->
<!--                                                </el-table-column>-->
<!--                                                <el-table-column prop="JSGMDQ" label="独立大桥(米)" width="110" align="right">-->
<!--                                                    <template slot-scope="scope">-->
<!--                                                        {{scope.row.JSGMDQ ? scope.row.JSGMDQ.toFixed(2) : ''}}-->
<!--                                                    </template>-->
<!--                                                </el-table-column>-->
<!--                                                <el-table-column prop="JSGMSD" label="独立隧道(米)" width="110" align="right">-->
<!--                                                    <template slot-scope="scope">-->
<!--                                                        {{scope.row.JSGMSD ? scope.row.JSGMSD.toFixed(2) : ''}}-->
<!--                                                    </template>-->
<!--                                                </el-table-column>-->
<!--                                            </el-table-column>-->
                                            <el-table-column label="文件上传情况" :width="150"  align="center">
                                                <el-table-column prop="XMGKFJ" label="可研批复" width="90" align="center">
                                                    <template slot-scope="scope">
                                                        <div style="text-align: center;color: #5cb85c" v-if="scope.row.XMGKFJ !=null && scope.row.XMGKFJ != ''"><i class="el-icon-check"></i></div>
                                                        <div style="text-align: center;color: #b80c18" v-else><i class="el-icon-close"></i></div>
                                                    </template>
                                                </el-table-column>
                                                <el-table-column prop="CBSJFJ" label="初设批复" width="90" align="center">
                                                    <template slot-scope="scope">
                                                        <div style="text-align: center;color: #5cb85c" v-if="scope.row.CBSJFJ !=null && scope.row.CBSJFJ != ''"><i class="el-icon-check"></i></div>
                                                        <div style="text-align: center;color: #b80c18" v-else><i class="el-icon-close"></i></div>
                                                    </template>
                                                </el-table-column>
                                                <el-table-column prop="SGTFJ" label="施工图批复" width="100" align="center">
                                                    <template slot-scope="scope">
                                                        <div style="text-align: center;color: #5cb85c" v-if="scope.row.SGTFJ !=null && scope.row.SGTFJ != ''"><i class="el-icon-check"></i></div>
                                                        <div style="text-align: center;color: #b80c18" v-else><i class="el-icon-close"></i></div>
                                                    </template>
                                                </el-table-column>
                                                <el-table-column prop="ZJAPYJFJ" label="资金安排意见" width="120" align="center">
                                                    <template slot-scope="scope">
                                                        <div style="text-align: center;color: #5cb85c" v-if="scope.row.ZJAPYJFJ !=null && scope.row.ZJAPYJFJ != ''"><i class="el-icon-check"></i></div>
                                                        <div style="text-align: center;color: #b80c18" v-else><i class="el-icon-close"></i></div>
                                                    </template>
                                                </el-table-column>
                                                <el-table-column prop="DFZFZJCNHFJ" label="承诺函" width="90" align="center">
                                                    <template slot-scope="scope">
                                                        <div style="text-align: center;color: #5cb85c" v-if="scope.row.DFZFZJCNHFJ !=null && scope.row.DFZFZJCNHFJ != ''"><i class="el-icon-check"></i></div>
                                                        <div style="text-align: center;color: #b80c18" v-else><i class="el-icon-close"></i></div>
                                                    </template>
                                                </el-table-column>
                                                <el-table-column prop="XMDZDTFJ" label="项目地理信息" width="120" align="center">
                                                    <template slot-scope="scope">
                                                        <div style="text-align: center;color: #5cb85c" v-if="scope.row.XMDZDTFJ !=null && scope.row.XMDZDTFJ != ''"><i class="el-icon-check"></i></div>
                                                        <div style="text-align: center;color: #b80c18" v-else><i class="el-icon-close"></i></div>
                                                    </template>
                                                </el-table-column>
                                                <el-table-column prop="SGHTFJ" label="施工合同" width="90" align="center">
                                                    <template slot-scope="scope">
                                                        <div style="text-align: center;color: #5cb85c" v-if="scope.row.SGHTFJ !=null && scope.row.SGHTFJ != ''"><i class="el-icon-check"></i></div>
                                                        <div style="text-align: center;color: #b80c18" v-else><i class="el-icon-close"></i></div>
                                                    </template>
                                                </el-table-column>
                                                <el-table-column prop="SGXKFJ" label="施工许可" width="90" align="center">
                                                    <template slot-scope="scope">
                                                        <div style="text-align: center;color: #5cb85c" v-if="scope.row.SGXKFJ !=null && scope.row.SGXKFJ != ''"><i class="el-icon-check"></i></div>
                                                        <div style="text-align: center;color: #b80c18" v-else><i class="el-icon-close"></i></div>
                                                    </template>
                                                </el-table-column>
                                                <el-table-column prop="SGZBTZSFJ" label="施工中标通知书" width="120" align="center">
                                                    <template slot-scope="scope">
                                                        <div style="text-align: center;color: #5cb85c" v-if="scope.row.SGZBTZSFJ !=null && scope.row.SGZBTZSFJ != ''"><i class="el-icon-check"></i></div>
                                                        <div style="text-align: center;color: #b80c18" v-else><i class="el-icon-close"></i></div>
                                                    </template>
                                                </el-table-column>
                                                <el-table-column prop="QTFJ" label="其他文件" width="90" align="center">
                                                    <template slot-scope="scope">
                                                        <div style="text-align: center;color: #5cb85c" v-if="scope.row.QTFJ !=null && scope.row.QTFJ != ''"><i class="el-icon-check"></i></div>
                                                        <div style="text-align: center;color: #b80c18" v-else><i class="el-icon-close"></i></div>
                                                    </template>
                                                </el-table-column>
                                            </el-table-column>
                                            <el-table-column prop="ZTZ" label="总投资(万元)" :width="120" align="center" sortable show-overflow-tooltip>
                                                <template slot-scope="scope">
                                                    <div style="text-align: right">
                                                        <!-- {{scope.row.ZTZ ? thousandBitSeparator(scope.row.ZTZ.toFixed(0) ): ''}} -->
                                                        {{scope.row.JHZZJHJ}}
                                                    </div>
                                                </template>
                                            </el-table-column>
                                        </el-table>
                                        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[30, 50, 100, 400]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </el-col>
                </el-row>
            </el-main>
        </el-container>
        <div class="editWrap">
            <!--            详情弹窗-->
            <el-drawer :visible.sync="showAddModel" @close="closeDrawer" :before-close="closeDrawer2" size="1300px" :title="XMMC"  class="editModal">
                <el-form :disabled="!isLock"  :model="detail"  ref="detailForm"  class="form-inline" v-loading="uploadLoading">
                    <el-tabs v-model="activeName" type="card">
                        <el-tab-pane label="基本信息" name="0">
                            <div style="overflow: auto;height: calc(100vh - 130px);">
                            <div class="col" >
                                <div class="col-1">
                                    <el-divider>基本信息</el-divider>
                                    <el-form-item  label="计划年度" prop="JHND" >
                                        <el-input disabled :placeholder="nowYear" v-model="detail.JHND" ></el-input>
                                    </el-form-item>
                                    <el-form-item label="计划批次"  prop="JHPC" :rules="[{ required: true, message:'请选择计划批次',trigger: 'blur' }]">
                                        <el-select placeholder="请选择计划批次" v-model="detail.JHPC" >
                                            <el-option label="第一批" value="第一批"></el-option>
<!--                                            <el-option label="第一批大本计划" value="第一批大本计划"></el-option>-->
<!--                                            <el-option label="第二批" value="第二批"></el-option>-->
<!--                                            <el-option label="第三批" value="第三批"></el-option>-->
<!--                                            <el-option label="公路投资计划" value="公路投资计划"></el-option>-->
                                        </el-select>
<!--                                        <span style="color: red">提示: 本次填报批次为第二批</span>-->
                                    </el-form-item>
                                    <el-form-item label="信息来源"  prop="XXLY" :rules="[{ required: true, message:'请选择信息来源',trigger: 'blur' }]">
                                        <el-select placeholder="请选择信息来源" v-model="detail.XXLY" >
                                            <el-option label="工可" value="工可"></el-option>
                                            <el-option label="初设" value="初设"></el-option>
                                        </el-select>
                                    </el-form-item>
                                    <!-- :rules="[{ required: true, message:'请选择项目类别',trigger: 'change' }]" -->
                                    <el-form-item label="项目类别"  prop="XMLB" >
                                        <el-select  @change="changeXLXZ" placeholder="请选择项目类别" v-model="detail.XMLB" >
                                            <el-option label="高速公路" value="高速公路"></el-option>
                                            <el-option label="普通国道" value="普通国道"></el-option>
                                            <el-option label="普通省道" value="普通省道"></el-option>
                                            <el-option label="G331及质量提升工程" value="G331及质量提升工程"></el-option>
                                        </el-select>
                                    </el-form-item>
                                    <!-- :rules="[{ required: true, message:'请选择项目所在地',trigger: 'change' }]" -->
                                    <el-form-item label="项目所在地">
                                        <!-- <el-cascader @change="changeAddress" collapse-tags size="medium" v-model="detail.buildAddress" :options="regions" :props="{ multiple: true}" clearable ></el-cascader> -->
                                        <!-- checkStrictly: true -->
                                        <!-- <el-cascader @change="changeAddress" disabled  collapse-tags size="medium" v-model="detail.buildAddress" :options="regions" :props="{ }" clearable ></el-cascader> -->
                                        <el-input placeholder="项目所在地" disabled :value="setDSQX(detail)" ></el-input>
                                    </el-form-item>
                                    <!-- disabled -->
                                    <el-form-item label="项目名称"  prop="XMMC" :rules="[{ required: true, message:'请输入项目名称',trigger: 'blur' }]">
                                        <el-input placeholder="请输入项目名称" :disabled="!isEdit" v-model="detail.XMMC" ></el-input>
                                    </el-form-item>
                                    <el-form-item label='对应前期项目名称' :rules="[{ required: true, message:'请输入项目名称',trigger: 'blur' }]">
                                        <div class="dwmcWrap">
                                            <el-tooltip  :content="XMMC" placement="top" v-if="XMMC!= ''">
                                                <div class="dwmc" :class="{dis:!isLock||!isEdit}" @click="openTable">{{XMMC}}</div>
                                            </el-tooltip>
                                            <div v-else class="dwmc" :class="{dis:!isLock||!isEdit}" @click="openTable">{{XMMC}}</div>
                                            <div class="plus" :class="{dis:!isLock||!isEdit}"><i @click="openTable" class="el-icon-circle-plus"></i></div>
                                        </div>
                                    </el-form-item>
                                    <el-form-item label='对应规划项目名称'>
                                        <el-input disabled placeholder="请选择前期项目" v-model="detail.SSWXMMC" ></el-input>
                                    </el-form-item>
                                    <el-form-item  label="路线编号" prop="LXBH" >
                                        <el-input placeholder="路线编号" v-model="detail.LXBH" ></el-input>
                                    </el-form-item>
                                    <el-form-item label="项目所在地区特殊性" prop="DQTSX">
                                        <el-select placeholder="请选择"  v-model="detail.DQTSX"  >
                                            <el-option label="请选择" value=""></el-option>
                                            <el-option label="集中连片特困地区" value="集中连片特困地区"></el-option>
                                            <el-option label="革命老区" value="革命老区"></el-option>
                                            <el-option label="国贫县" value="国贫县"></el-option>
                                            <el-option label="边境县" value="边境县"></el-option>
                                            <el-option label="少数名族县" value="少数名族县"></el-option>
                                        </el-select>
                                    </el-form-item>

                                    <el-form-item label="线路性质"  prop="XLXZ" :rules="[{ required: true, message:'请选择线路性质',trigger: 'change' }]">
                                        <el-select placeholder="请选择线路性质" v-model="detail.XLXZ" >
                                            <el-option label="国家高速" value="国家高速"></el-option>
                                            <el-option label="地方高速" value="地方高速"></el-option>
                                            <el-option label="国道" value="国道"></el-option>
                                            <el-option label="省道" value="省道"></el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="建设性质"   prop="JSXZ" :rules="[{ required: true, message:'请选择建设性质',trigger: 'change' }]">
                                        <el-select placeholder="请选择建设性质"  v-model="detail.JSXZ">
                                            <el-option label="新建" value="新建"></el-option>
                                            <el-option label="新改建" value="新改建"></el-option>
                                            <el-option label="改扩建" value="改扩建"></el-option>
                                            <el-option label="路面改造" value="路面改造"></el-option>
                                            <el-option label="其他" value="其他"></el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="开工年" >
                                        <el-date-picker :disabled="!isEdit"   style="width: 330px"
                                                         format="yyyy"
                                                         value-format="yyyy"
                                                         v-model="detail.KGN" type="year" placeholder="选择日期">
                                        </el-date-picker>
                                    </el-form-item>
                                    <el-form-item label="完工年" >
                                        <el-date-picker :disabled="!isEdit"   style="width: 330px"
                                                         format="yyyy"
                                                         value-format="yyyy"
                                                         v-model="detail.WGN" type="year" placeholder="选择日期">
                                        </el-date-picker>
                                    </el-form-item>
                                    <el-form-item label='备注'>
                                        <el-input autosize rows="4"  placeholder="请输入备注" type="textarea" v-model="detail.BZ"></el-input>
                                    </el-form-item>
                                </div>
                                <div class="col-1">
                                    <!-- <el-divider>项目法人单位信息</el-divider>
                                    <el-form-item label="单位名称" >
                                        <div class="dwmcWrap" style="margin-left: 0 ">
                                            <div class="dwmc"   @click="showFr">{{XMFRDW}}</div>
                                            <div class="plus"><i @click="showFr" class="el-icon-circle-plus"></i></div>
                                        </div>
                                    </el-form-item> -->

                                    <el-divider>监管信息</el-divider>
                                    <el-form-item  label="日常监管直接责任单位" :rules="[{ required: true, message:'请填写日常监管直接责任单位',trigger: 'change' }]" prop="JGZRDW" >
                                        <el-input  placeholder="日常监管直接责任单位" v-model="detail.JGZRDW" ></el-input>
                                    </el-form-item>
                                    <el-form-item  label="监管责任人" :rules="[{ required: true, message:'请填写监管责任人',trigger: 'change' }]" prop="JGZRR" >
                                        <el-input  placeholder="监管责任人" v-model="detail.JGZRR" ></el-input>
                                    </el-form-item>
                                    <!-- <el-form-item  label="责任单位" :rules="[{ required: true, message:'请填写责任单位',trigger: 'change' }]" prop="ZRDW" >
                                        <el-input  placeholder="责任单位" v-model="detail.ZRDW" ></el-input>
                                    </el-form-item> -->
                                    <el-form-item label="责任单位联系电话" :rules="[{ required: true, message:'请填写责任单位联系电话',trigger: 'change' }]" prop="ZRDWDH">
                                        <el-input placeholder="责任单位联系电话" v-model="detail.ZRDWDH" ></el-input>
                                    </el-form-item>
                                    <!-- <el-form-item label="责任人" :rules="[{ required: true, message:'请填写责任人',trigger: 'change' }]" prop="ZRR">
                                        <el-input placeholder="责任人" v-model="detail.ZRR" ></el-input>
                                    </el-form-item> -->
                                    <el-form-item label="责任人联系电话" :rules="[{ required: true, message:'请填写责任人联系电话',trigger: 'change' }]" prop="ZRRDH">
                                        <el-input placeholder="责任人联系电话" v-model="detail.ZRRDH" ></el-input>
                                    </el-form-item>
                                </div>
                                <div class="col-1">
                                    <el-divider>前期工作情况</el-divider>
                                         <!-- :rules="[{ required: true, message:'请填写工可或核准批复文号',trigger: 'change' }]" -->
                                      <el-form-item label="工可或核准批复文号"  prop="QQHZXXX"  :rules="[{  required: true,validator: (rule, value, callback)=>{checkQQXXX(rule, 'QQHZXXX', callback)},trigger: 'blur' }]">
                                        <el-input class="xxh"  placeholder="单位" v-model="QQHZXXX.hz" ></el-input>
                                        〔
                                        <el-input class="xxh"  type="number"  placeholder="年份" v-model="QQHZXXX.nf" onkeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"></el-input>
                                        〕
                                        <el-input  class="xxh"   placeholder="序号" v-model="QQHZXXX.jw" ></el-input>
                                        号
                                    </el-form-item>
<!--                                    <el-form-item  label="初设或施工图批复文号"  prop="QQSGTXXX" :rules="[{  required: true,validator: (rule, value, callback)=>{checkQQXXX(rule, 'QQSGTXXX', callback)},trigger: 'blur' }]">-->
                                    <el-form-item  label="初设或施工图批复文号"  prop="QQSGTXXX" >
                                        <!-- <el-input  placeholder="初设或施工图批复文号" v-model="detail.QQSGT" ></el-input> -->
                                         <el-input class="xxh"  placeholder="单位" v-model="QQSGTXXX.hz" ></el-input>
                                        〔
                                        <el-input class="xxh"  type="number"  placeholder="年份" v-model="QQSGTXXX.nf" onkeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"></el-input>
                                        〕
                                        <el-input  class="xxh"   placeholder="序号" v-model="QQSGTXXX.jw" ></el-input>
                                        号
                                    </el-form-item>
                                    <!-- <el-form-item  label="工可批复文号"  prop="QQGK" >
                                        <el-input  placeholder="工可批复文号" v-model="detail.QQGK" ></el-input>
                                    </el-form-item>
                                    <el-form-item  label="核准批复文号"  prop="QQHZ" >
                                        <el-input  placeholder="核准批复文号" v-model="detail.QQHZ" ></el-input>
                                    </el-form-item>
                                    <el-form-item  label="初设批复文号" prop="QQCS" >
                                        <el-input  placeholder="初设批复文号" v-model="detail.QQCS" ></el-input>
                                    </el-form-item>
                                    <el-form-item  label="施工图批复文号" prop="QQSGT" >
                                        <el-input  placeholder="施工图批复文号" v-model="detail.QQSGT" ></el-input>
                                    </el-form-item> -->
                                </div>
                            </div>
<!--                            <el-row>-->
<!--                                <el-form-item  label="计划年度" label-width="168px" prop="JHND" >-->
<!--                                    <el-input disabled placeholder="计划年度" v-model="detail.JHND" ></el-input>-->
<!--                                </el-form-item>-->


<!--                            </el-row>-->
<!--                            <el-row>-->

<!--                            <el-form-item label="集中连片特困地区" label-width="168px" prop="TKDQ">-->
<!--                                    <el-input placeholder="请输入项目所属集中连片特困地区" v-model="detail.TKDQ" ></el-input>-->
<!--                                </el-form-item>-->
<!--                            </el-row>-->
<!--                            <el-row>-->
<!--                                <el-form-item label="革命老区" label-width="168px" prop="GMLQ">-->
<!--                                    <el-input placeholder="请输入革命老区" v-model="detail.GMLQ" ></el-input>-->
<!--                                </el-form-item>-->
<!--                                <el-form-item label="国贫县" label-width="168px" prop="GPX">-->
<!--                                    <el-input placeholder="请输入国贫县" v-model="detail.GPX" ></el-input>-->
<!--                                </el-form-item>-->
<!--                                <el-form-item label="边境县" label-width="168px" prop="BJX">-->
<!--                                    <el-input placeholder="请输入边境县" v-model="detail.BJX" ></el-input>-->
<!--                                </el-form-item>-->
<!--                            </el-row>-->
<!--                            <el-row>-->
<!--                                <el-form-item label="少数名族县" label-width="168px" prop="SSMZ">-->
<!--                                    <el-input placeholder="少数名族县" v-model="detail.SSMZ" ></el-input>-->
<!--                                </el-form-item>-->

<!--                            </el-row>-->
<!--                            <el-row>-->
<!--                                <el-form-item label="开工年" label-width="168px">-->
<!--                                    <el-date-picker  style="width: 178px"-->

<!--                                                     format="yyyy"-->
<!--                                                     value-format="yyyy-MM-dd"-->
<!--                                                     v-model="detail.KGN" type="year" placeholder="选择日期">-->
<!--                                    </el-date-picker>-->
<!--                                </el-form-item>-->
<!--                                <el-form-item label="完工年" label-width="168px">-->
<!--                                    <el-date-picker  style="width: 178px"-->

<!--                                                     format="yyyy"-->
<!--                                                     value-format="yyyy-MM-dd"-->
<!--                                                     v-model="detail.WGN" type="year" placeholder="选择日期">-->
<!--                                    </el-date-picker>-->
<!--                                </el-form-item>-->
<!--                            </el-row>-->
</div>
                        </el-tab-pane>
                        <el-tab-pane label="建设规模（公里）" name="1">
                            <div class="col" style="justify-content: flex-start;height: 580px">
                                <div class="col-1">
                                    <el-form-item label="合计"  prop="JSGMHJ" :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">
                                        <el-input placeholder="合计" disabled v-model="detail.JSGMHJ"></el-input>
                                    </el-form-item>
                                    <el-form-item label="高速"  prop="JSGMGS">
                                        <el-input placeholder="高速" :disabled="!isEdit" @input="changeJstotal($event,'gs')" v-model="detail.JSGMGS"></el-input>
                                    </el-form-item>
                                    <el-form-item label="一级"  prop="JSGMYJ">
                                        <el-input placeholder="一级" :disabled="!isEdit" @input="changeJstotal($event,'gs')" v-model="detail.JSGMYJ"></el-input>
                                    </el-form-item>
                                    <el-form-item label="二级"  prop="JSGMEJ">
                                        <el-input placeholder="二级" :disabled="!isEdit" @input="changeJstotal($event,'gs')" v-model="detail.JSGMEJ"></el-input>
                                    </el-form-item>
                                    <el-form-item label="三级" prop="JSGMSJ">
                                        <el-input placeholder="三级" :disabled="!isEdit" @input="changeJstotal($event,'gs')" v-model="detail.JSGMSJ"></el-input>
                                    </el-form-item>
                                    <el-form-item label="四级"  prop="JSGMSI">
                                        <el-input placeholder="四级" :disabled="!isEdit" @input="changeJstotal($event,'gs')" v-model="detail.JSGMSI"></el-input>
                                    </el-form-item>
                                </div>
                                <div class="col-1" style="margin-left: 20px">
                                    <el-form-item label="独立大桥(超过1000米上报)"  prop="JSGMDQ">
                                        <el-input placeholder="独立大桥" :disabled="!isEdit" @input="changeJstotal($event,'gs')" v-model="detail.JSGMDQ"><template #suffix>延米</template></el-input>
                                    </el-form-item>
                                    <el-form-item label="独立隧道"   prop="JSGMSD">
                                        <el-input placeholder="独立隧道" :disabled="!isEdit" @input="changeJstotal($event,'gs')" v-model="detail.JSGMSD"><template #suffix>延米</template></el-input>
                                    </el-form-item>
                                </div>
                                </div>
<!--                            <el-row>-->
<!--                                -->
<!--                                <el-form-item label="高速" label-width="168px"  prop="JSGMGS" :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">-->
<!--                                    <el-input placeholder="高速" @input="changeJstotal($event,'gs')" v-model="detail.JSGMGS"></el-input>-->
<!--                                </el-form-item>-->
<!--                                <el-form-item label="一级" label-width="168px"  prop="JSGMYJ" :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">-->
<!--                                    <el-input placeholder="一级" @input="changeJstotal($event,'gs')" v-model="detail.JSGMYJ"></el-input>-->
<!--                                </el-form-item>-->
<!--                            </el-row>-->
<!--                            <el-row>-->
<!--                                <el-form-item label="二级" label-width="168px"  prop="JSGMEJ" :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">-->
<!--                                    <el-input placeholder="二级" @input="changeJstotal($event,'gs')" v-model="detail.JSGMEJ"></el-input>-->
<!--                                </el-form-item>-->
<!--                                <el-form-item label="三级" label-width="168px"  prop="JSGMSJ" :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">-->
<!--                                    <el-input placeholder="三级" @input="changeJstotal($event,'gs')" v-model="detail.JSGMSJ"></el-input>-->
<!--                                </el-form-item>-->
<!--                                <el-form-item label="四级" label-width="168px"  prop="JSGMSI" :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">-->
<!--                                    <el-input placeholder="四级" @input="changeJstotal($event,'gs')" v-model="detail.JSGMSI"></el-input>-->
<!--                                </el-form-item>-->
<!--                            </el-row>-->
<!--                            <el-row>-->
<!--                                <el-form-item label="独立大桥" label-width="168px" prop="JSGMDQ" :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">-->
<!--                                    <el-input placeholder="独立大桥" @input="changeJstotal($event,'gs')" v-model="detail.JSGMDQ"><template #suffix>延米</template></el-input>-->
<!--                                </el-form-item>-->
<!--                                <el-form-item label="独立隧道" label-width="168px"  prop="JSGMSD" :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">-->
<!--                                    <el-input placeholder="独立隧道" @input="changeJstotal($event,'gs')" v-model="detail.JSGMSD"><template #suffix>延米</template></el-input>-->
<!--                                </el-form-item>-->
<!--                            </el-row>-->
                        </el-tab-pane>
                        <el-tab-pane  label="资金计划（万元）" name="2">
                            <div style="overflow: auto;
    height: calc(100vh - 180px);">
                                <table style="text-align:center;margin-top: 20px;width:100%"  class="littleTable">
                                    <!-- <tr>
                                        <td colspan="11" style="background: #F5F7FA">
                                            <div class="cont" style="text-align: center;width: 100%;font-weight: 600;color: #333">计划总投资</div>
                                        </td>
                                    </tr> -->
                                    <tr>
                                        <td rowspan="2" style="background: #f5f7fa;">
                                            <div class="label2" >年度批次</div>
                                        </td>
                                        <td rowspan="2" style="background: #f5f7fa;">
                                            <div class="label2" >合计</div>
                                        </td>
                                        <td colspan="2" style="background: #f5f7fa;">
                                            <div class="label2" style="line-height: 1.7;">中央投资 <div>（车购税）</div> </div>
                                        </td>
                                        <td rowspan="2" style="background: #f5f7fa;">
                                            <div class="label2" style="line-height: 1.7;">省级补助资金 </div>
                                        </td>
                                        <td colspan="2">
                                            <div class="label2" >省配套资金</div>
                                        </td>
                                        <td  rowspan="2" style="background: #f5f7fa;">
                                            <div class="label2"  >专项债券</div>
                                        </td>
                                        <td  rowspan="2" style="background: #f5f7fa;">
                                            <div class="label2"  >省市县自筹</div>
                                        </td>
                                        <td colspan="3">
                                            <div class="label2" >地方配套</div>
                                        </td>
                                        <td  rowspan="3" style="background: #f5f7fa;">
                                            <div class="label2"  >主要建设内容</div>
                                        </td>
                                        <!-- <td  rowspan="2" style="background: #f5f7fa;">
                                            <div class="label2"  >新增生产能力</div>
                                        </td> -->
                                    </tr>
                                    <tr>
                                        <td colspan="2">
                                            <div class="label2"  ></div>
                                        </td>
<!--                                        <td>-->
<!--                                            <div class="label2"  >二批</div>-->
<!--                                        </td>-->
                                        <td>
                                            <div class="label2"  >财政资金</div>
                                        </td>
                                        <td>
                                            <div class="label2"  >成品油税费改革转移支付资金</div>
                                        </td>
                                        <td>
                                            <div class="label2"  >银行贷款</div>
                                        </td>
                                        <td>
                                            <div class="label2"  >企业自筹</div>
                                        </td>
                                        <td>
                                            <div class="label2"  >地方自筹</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="12" style="background: #F5F7FA">
                                            <div style="width: 100%;text-align:left;font-weight: 600;color: #333;padding: 10px 15px;">计划总资金</div>
                                        </td>
                                    </tr>
                                    <tr class="newform">
                                        <td class="ty" style="width:122px">
                                            <el-input size="medium" class="inputcenter" placeholder="-" disabled></el-input>
                                        </td>
                                        <td class="ty">
                                            <el-form-item  prop="JHZZJHJ">
                                                <el-input size="medium"  type="number" class="inputright" v-model="detail.JHZZJHJ" placeholder="合计"></el-input>
                                            </el-form-item>
                                        </td>
                                        <td class="ty" colspan="2">
                                            <el-form-item prop="JHZZJZYTZ" >
                                                <el-input  size="medium"  type="number"  class="inputright" v-model="detail.JHZZJZYTZ" placeholder="中央投资"></el-input>
                                            </el-form-item>
                                        </td>
                                        <td class="ty">
                                            <el-form-item prop="SJBZZJ" >
                                                <el-input  size="medium"  type="number"  class="inputright" v-model="detail.SJBZZJ" placeholder="省级补助资金"></el-input>
                                            </el-form-item>
                                        </td>
                                        <td class="ty">
                                            <el-form-item prop="JHZZJCZZJ" >
                                                <el-input  size="medium"  type="number"  class="inputright"  v-model="detail.JHZZJCZZJ" placeholder="财政资金"></el-input>
                                            </el-form-item>
                                        </td>
                                        <td class="ty">
                                            <el-form-item prop="JHZZJCPYSF" >
                                                <el-input  type="number" class="inputright" style="width: 200px"   size="medium" v-model="detail.JHZZJCPYSF" placeholder="成品油税费改革转移支付资金"></el-input>
                                            </el-form-item>
                                        </td>
                                        <td class="ty">
                                            <el-form-item prop="JHZZJZXZQ" >
                                                <el-input  type="number" class="inputright" size="medium"   v-model="detail.JHZZJZXZQ" placeholder="专项债券"></el-input>
                                            </el-form-item>
                                        </td>
                                        <td class="ty">
                                            <el-form-item prop="JHZZJSSXZC" >
                                                <el-input  type="number" class="inputright"  size="medium"  v-model="detail.JHZZJSSXZC" placeholder="省市县自筹"></el-input>
                                            </el-form-item>
                                        </td>
                                        <td class="ty">
                                            <el-form-item prop="JHZZJYHDK" >
                                                <el-input  type="number" class="inputright"   size="medium"  v-model="detail.JHZZJYHDK" placeholder="银行贷款"></el-input>
                                            </el-form-item>
                                        </td>
                                        <td class="ty">
                                            <el-form-item prop="JHZZJQYZC" >
                                                <el-input  type="number" class="inputright"  size="medium"  v-model="detail.JHZZJQYZC" placeholder="企业自筹"></el-input>
                                            </el-form-item>
                                        </td>
                                        <td class="ty">
                                            <el-form-item prop="JHZZJDFZC" >
                                                <el-input  type="number" class="inputright"  size="medium"  v-model="detail.JHZZJDFZC" placeholder="地方自筹"></el-input>
                                            </el-form-item>
                                        </td>
                                        <td class="ty">
                                            <el-form-item prop="JHZZJJSNR" style="width:160px">
                                                <el-select v-model="detail.JHZZJJSNR" placeholder="请选择">
                                                    <el-option label="请选择" value=""></el-option>
                                                    <el-option label="路基桥涵" value="路基桥涵"></el-option>
                                                    <el-option label="路基路面" value="路基路面"></el-option>
                                                </el-select>
                                                <!-- <el-input  size="medium" class="inputcenter" v-model="detail.JHZZJJSNR" placeholder="主要建设内容"></el-input> -->
                                            </el-form-item>
                                        </td>
                                        <!-- <td class="ty">
                                            <el-form-item prop="JHZZJXZSCNL"   style="width:100px">
                                                <el-input  size="medium" class="inputcenter" v-model="detail.JHZZJXZSCNL" placeholder="新增生产能力"></el-input>
                                            </el-form-item>
                                        </td> -->
                                    </tr>
                                    <tr>
                                        <td colspan="14" style="background: #F5F7FA">
                                            <div style="width: 100%;text-align:left;font-weight: 600;color: #333;padding: 10px 15px;">
                                                累计下达资金
                                                <span v-if="ljxdtzlist.length <= 0">（没有累计下达资金）</span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr class="newform" v-for="item in ljxdtzlist" :key="item.ID"  >
                                        <td class="ty" style="width:122px">
                                            <el-input size="medium" class="inputcenter" :value="(item.JHND+'-'+item.JHPC)||'-'"  disabled placeholder="计划年度"></el-input>
                                        </td>
                                        <td class="ty">
                                            <!-- DNJHGJ -->
                                            <el-input size="medium" class="inputright" :value="item.NJHTZHJ||0"  disabled placeholder="合计"></el-input>
                                        </td>
                                        <td class="ty" colspan="2">
                                            <el-input  size="medium" class="inputright" :value="item.NJHTZZYTZ||0" disabled placeholder="中央投资"></el-input>
                                        </td>
                                        <td class="ty">
                                            <el-input  size="medium" class="inputright" :value="item.NJHTZSJBZZJ||0" disabled placeholder="省级补助资金"></el-input>
                                        </td>
                                        <td class="ty">
                                            <el-input  size="medium" class="inputright" :value="item.NJHTZCZZJ||0" disabled placeholder="财政资金"></el-input>
                                        </td>
                                        <td class="ty">
                                            <el-input style="width: 200px" :value="item.NJHTZCPYSF||0" size="medium" class="inputright" disabled placeholder="成品油税费改革转移支付资金"></el-input>
                                        </td>
                                        <td class="ty">
                                            <el-input  size="medium" class="inputright" :value="item.NJHTZZXZQ||0" disabled placeholder="专项债券"></el-input>
                                        </td>
                                        <td class="ty">
                                            <el-input  size="medium" class="inputright"  :value="item.NJHTZSSXZC||0" disabled placeholder="省市县自筹"></el-input>
                                        </td>
                                        <td class="ty">
                                            <el-input  size="medium" class="inputright" :value="item.NJHTZYHDK||0" disabled placeholder="银行贷款"></el-input>
                                        </td>
                                        <td class="ty">
                                            <el-input  size="medium" class="inputright" :value="item.NJHTZZQYZC||0" disabled placeholder="企业自筹"></el-input>
                                        </td>
                                        <td class="ty">
                                            <el-input  size="medium" class="inputright" :value="item.NJHTZDFZC||0" disabled placeholder="地方自筹"></el-input>
                                        </td>
                                        <td class="ty" style="width:160px">
                                            <el-input  size="medium" class="inputcenter" :value="item.NJHTZJSNR||' '" disabled placeholder="主要建设内容"></el-input>
                                        </td>
                                        <!-- <td class="ty"  style="width:100px">
                                           <el-input  size="medium" class="inputcenter" :value="item.NJHTZXZSCNL||' '" disabled placeholder="新增生产能力"></el-input>
                                       </td> -->
                                    </tr>


                                    <tr class="newform" v-if="ljxdtzlist.length > 0">
                                        <td class="ty" style="width:122px">
                                            <el-input size="medium" class="inputcenter"  disabled placeholder="合计"></el-input>
                                        </td>
                                        <td class="ty">
                                            <!-- DNJHGJ -->
                                            <el-input size="medium" class="inputright" :value="ljxdhj.NJHTZHJ||0"  disabled placeholder="合计"></el-input>
                                        </td>
                                        <td class="ty" colspan="2">
                                            <el-input  size="medium" class="inputright" :value="ljxdhj.NJHTZZYTZ||0" disabled placeholder="中央投资"></el-input>
                                        </td>
                                        <td class="ty">
                                            <el-input  size="medium" class="inputright" :value="ljxdhj.NJHTZSJBZZJ||0" disabled placeholder="省级补助资金"></el-input>
                                        </td>
                                        <td class="ty">
                                            <el-input  size="medium" class="inputright" :value="ljxdhj.NJHTZCZZJ||0" disabled placeholder="财政资金"></el-input>
                                        </td>
                                        <td class="ty">
                                            <el-input style="width: 200px" :value="ljxdhj.NJHTZCPYSF||0" size="medium" class="inputright" disabled placeholder="成品油税费改革转移支付资金"></el-input>
                                        </td>
                                        <td class="ty">
                                            <el-input  size="medium" class="inputright" :value="ljxdhj.NJHTZZXZQ||0" disabled placeholder="专项债券"></el-input>
                                        </td>
                                        <td class="ty">
                                            <el-input  size="medium" class="inputright" :value="ljxdhj.NJHTZSSXZC||0" disabled placeholder="省市县自筹"></el-input>
                                        </td>
                                        <td class="ty">
                                            <el-input  size="medium" class="inputright" :value="ljxdhj.NJHTZYHDK||0" disabled placeholder="银行贷款"></el-input>
                                        </td>
                                        <td class="ty">
                                            <el-input  size="medium" class="inputright" :value="ljxdhj.NJHTZZQYZC||0" disabled placeholder="企业自筹"></el-input>
                                        </td>
                                        <td class="ty">
                                            <el-input  size="medium" class="inputright" :value="ljxdhj.NJHTZDFZC||0" disabled placeholder="地方自筹"></el-input>
                                        </td>
                                        <td class="ty" style="width:160px">
                                            <el-input  size="medium" class="inputcenter" :value="' '" disabled placeholder="主要建设内容"></el-input>
                                        </td>
                                        <!-- <td class="ty"  style="width:100px">
                                           <el-input  size="medium" class="inputcenter" :value="item.NJHTZXZSCNL||' '" disabled placeholder="新增生产能力"></el-input>
                                       </td> -->
                                    </tr>


                                    <tr>
                                        <td colspan="12" style="background: #F5F7FA">
                                            <div style="width: 100%;text-align:left;font-weight: 600;color: #333;padding: 10px 15px;">累计完成投资</div>
                                        </td>
                                    </tr>
                                    <tr class="newform">
                                        <td class="ty" style="width:122px">
                                            <el-input size="medium" class="inputcenter" placeholder="-" disabled></el-input>
                                        </td>
                                        <td class="ty">
                                            <el-form-item  prop="LJWCTZHJ">
                                                <el-input size="medium" class="inputright" disabled v-model="detail.LJWCTZHJ" placeholder="合计"></el-input>
                                            </el-form-item>
                                        </td>
                                        <td class="ty" colspan="2">
                                            <el-form-item prop="LJWCTZZYTZ">
                                                <el-input  size="medium" class="inputright" disabled v-model="detail.LJWCTZZYTZ" placeholder="中央投资"></el-input>
                                            </el-form-item>
                                        </td>
                                        <td class="ty">
                                            <el-form-item prop="LJXDSJBZZJ">
                                                <el-input  size="medium" class="inputright" disabled v-model="detail.LJXDSJBZZJ" placeholder="省级补助资金"></el-input>
                                            </el-form-item>
                                        </td>
                                        <td class="ty">
                                            <el-form-item prop="LJXDCZZJ">
                                                <el-input  size="medium" class="inputright" disabled v-model="detail.LJXDCZZJ" placeholder="财政资金"></el-input>
                                            </el-form-item>
                                        </td>
                                        <td class="ty">
                                            <el-form-item prop="LJXDCPYSF">
                                                <el-input style="width: 200px" size="medium" class="inputright" disabled v-model="detail.LJXDCPYSF" placeholder="成品油税费改革转移支付资金"></el-input>
                                            </el-form-item>
                                        </td>
                                        <td class="ty">
                                            <el-form-item prop="LJWCTZZXZQ">
                                                <el-input  size="medium" class="inputright" disabled v-model="detail.LJWCTZZXZQ" placeholder="专项债券"></el-input>
                                            </el-form-item>
                                        </td>
                                        <td class="ty">
                                            <el-form-item prop="LJWCTZSSXZC">
                                                <el-input  size="medium" class="inputright" disabled v-model="detail.LJWCTZSSXZC" placeholder="省市县自筹"></el-input>
                                            </el-form-item>
                                        </td>
                                        <td class="ty">
                                            <el-form-item prop="LJWCTZYHDK">
                                                <el-input  size="medium" class="inputright" disabled v-model="detail.LJWCTZYHDK" placeholder="银行贷款"></el-input>
                                            </el-form-item>
                                        </td>
                                        <td class="ty">
                                            <el-form-item prop="LJWCTZQYZC">
                                                <el-input  size="medium" class="inputright" disabled v-model="detail.LJWCTZQYZC" placeholder="企业自筹"></el-input>
                                            </el-form-item>
                                        </td>
                                        <td class="ty">
                                            <el-form-item prop="LJXDDFZC">
                                                <el-input  size="medium" class="inputright" disabled v-model="detail.LJXDDFZC" placeholder="地方自筹"></el-input>
                                            </el-form-item>
                                        </td>
                                        <td class="ty">
                                            <el-form-item prop="LJWCTZJSNR" >
                                                <el-input  size="medium" class="inputcenter" disabled v-model="detail.LJWCTZJSNR" placeholder="主要建设内容"></el-input>
                                            </el-form-item>
                                        </td>
                                        <!-- <td class="ty">
                                           <el-form-item prop="LJWCTZXZSCNL"   style="width:100px">
                                               <el-input  size="medium" class="inputcenter" disabled v-model="detail.LJWCTZXZSCNL" placeholder="新增生产能力"></el-input>
                                           </el-form-item>
                                       </td> -->
                                    </tr>
                                    <tr>
                                        <td colspan="14" style="background: #F5F7FA">
<!--                                            <div style="width: 100%;text-align:left;font-weight: 600;color: #333;padding: 10px 15px;">{{nowYear}}年{{detail.JHPC}}计划资金</div>-->
                                            <div style="width: 100%;text-align:left;font-weight: 600;color: #333;padding: 10px 15px;">2025年第一批资金</div>
                                        </td>
                                    </tr>
                                    <tr class="newform">
                                        <td class="ty" style="width:122px">
                                            <el-input size="medium" class="inputcenter" placeholder="-" disabled></el-input>
                                        </td>
                                        <td class="ty">
                                            <el-form-item  >
                                                <el-input size="medium" disabled class="inputright" v-model="detail.NJHTZHJ" placeholder="合计"></el-input>
                                            </el-form-item>
                                        </td>
                                        <td class="ty" colspan="2">
                                            <el-form-item prop="NJHTZZYTZ" >
                                                <el-input  size="medium" class="inputright"   v-model="detail.NJHTZZYTZ" placeholder="车购税"></el-input>
                                            </el-form-item>
                                        </td>
<!--                                        <td class="ty">-->
<!--                                            <el-form-item prop="NJHTZZYTZ2" >-->
<!--                                                <el-input  size="medium" class="inputright"   v-model="detail.NJHTZZYTZ2" placeholder="二批"></el-input>-->
<!--                                            </el-form-item>-->
<!--                                        </td>-->
                                        <td class="ty">
                                            <el-form-item prop="NJHTZSJBZZJ" >
                                                <el-input  size="medium" class="inputright"   v-model="detail.NJHTZSJBZZJ" placeholder="省级补助资金"></el-input>
                                            </el-form-item>
                                        </td>
                                        <td class="ty">
                                            <el-form-item prop="NJHTZCZZJ" >
                                                <el-input  size="medium" class="inputright"  type="number" v-model="detail.NJHTZCZZJ" placeholder="财政资金"></el-input>
                                            </el-form-item>
                                        </td>
                                        <td class="ty">
                                            <el-form-item prop="NJHTZCPYSF" >
                                                <el-input style="width: 200px" size="medium" class="inputright"   type="number" v-model="detail.NJHTZCPYSF" placeholder="成品油税费改革转移支付资金"></el-input>
                                            </el-form-item>
                                        </td>
                                        <td class="ty">
                                            <el-form-item prop="NJHTZZXZQ" >
                                                <el-input  size="medium" class="inputright"  type="number"  v-model="detail.NJHTZZXZQ" placeholder="专项债券"></el-input>
                                            </el-form-item>
                                        </td>
                                        <td class="ty">
                                            <el-form-item prop="NJHTZSSXZC">
                                                <el-input  size="medium" class="inputright"  v-model="detail.NJHTZSSXZC" placeholder="省市县自筹"></el-input>
                                            </el-form-item>
                                        </td>
                                        <td class="ty">
                                            <el-form-item prop="NJHTZYHDK" >
                                                <el-input  size="medium" class="inputright"  type="number" v-model="detail.NJHTZYHDK" placeholder="银行贷款"></el-input>
                                            </el-form-item>
                                        </td>
                                        <td class="ty">
                                            <el-form-item prop="NJHTZZQYZC" >
                                                <el-input  size="medium" class="inputright" type="number" v-model="detail.NJHTZZQYZC" placeholder="企业自筹"></el-input>
                                            </el-form-item>
                                        </td>
                                        <td class="ty">
                                            <el-form-item prop="NJHTZDFZC" >
                                                <el-input  size="medium" class="inputright"  type="number" v-model="detail.NJHTZDFZC" placeholder="地方自筹"></el-input>
                                            </el-form-item>
                                        </td>
                                        <td class="ty">
                                            <el-form-item prop="NJHTZJSNR" style="width:160px" :rules="[{ required: true,message: '内容不能为空',trigger: 'blur' }]">
                                                <!-- <el-input  size="medium" class="inputcenter" v-model="detail.NJHTZJSNR" placeholder="主要建设内容"></el-input> -->
                                                <el-select v-model="detail.NJHTZJSNR" placeholder="请选择">
                                                    <el-option label="请选择" value=""></el-option>
                                                    <el-option label="路基桥涵" value="路基桥涵"></el-option>
                                                    <el-option label="路基路面" value="路基路面"></el-option>
                                                </el-select>
                                            </el-form-item>
                                        </td>
                                        <!-- <td class="ty">
                                           <el-form-item prop="NJHTZXZSCNL"   style="width:100px">
                                               <el-input  size="medium" class="inputcenter" v-model="detail.NJHTZXZSCNL" placeholder="新增生产能力"></el-input>
                                           </el-form-item>
                                       </td> -->
                                    </tr>
                                    <tr>
                                        <td colspan="14" style="background: #F5F7FA">
                                            <div style="width: 100%;text-align:left;font-weight: 600;color: #333;padding: 10px 15px;">剩余资金</div>
                                        </td>
                                    </tr>
                                    <tr class="newform">
                                        <td class="ty" style="width:122px">
                                            <el-input size="medium" class="inputcenter"  placeholder="-" disabled></el-input>
                                        </td>
                                        <td class="ty">
                                            <el-form-item  prop="SYZJHJ" :rules="[{ required: true, validator: (rule, value, callback)=>{checkNumFs(rule, value, callback)},trigger: 'blur' }]">
                                                <el-input size="medium" class="inputright"  :class="{colors:detail.SYZJHJ<0}" disabled v-model="detail.SYZJHJ" placeholder="合计"></el-input>
<!--                                                <div class="fale" v-if='detail.SYZJHJ < 0'>0</div>-->
<!--                                                <div  class="fale" v-else>{{detail.SYZJHJ }}</div>-->
                                            </el-form-item>
                                        </td>
                                        <td class="ty" colspan="2">
                                            <el-form-item prop="SYZJZYTZ" :rules="[{ required: true, validator: (rule, value, callback)=>{checkNumFs(rule, value, callback)},trigger: 'blur' }]">
                                                <el-input  size="medium" class="inputright"  :class="{colors:detail.SYZJZYTZ<0}" disabled v-model="detail.SYZJZYTZ" placeholder="中央投资"></el-input>
<!--                                                <div class="fale" v-if='detail.SYZJZYTZ < 0'>0</div>-->
<!--                                                <div  class="fale" v-else>{{detail.SYZJZYTZ }}</div>-->
                                            </el-form-item>
                                        </td>
                                        <td class="ty">
                                            <el-form-item prop="SYZJSJBZZJ" :rules="[{ required: true, validator: (rule, value, callback)=>{checkNumFs(rule, value, callback)},trigger: 'blur' }]">
                                                <el-input  size="medium" class="inputright"  :class="{colors:detail.SYZJSJBZZJ<0}" disabled v-model="detail.SYZJSJBZZJ" placeholder="省级补助资金"></el-input>
                                            </el-form-item>
                                        </td>
                                        <td class="ty">
                                            <el-form-item prop="SYZJCZZJ" >
                                                <el-input  size="medium" class="inputright"  :class="{colors:detail.SYZJCZZJ<0}" disabled v-model="detail.SYZJCZZJ" placeholder="财政资金"></el-input>
<!--                                                <div class="fale" v-if='detail.SYZJCZZJ < 0'>0</div>-->
<!--                                                <div  class="fale" v-else>{{detail.SYZJCZZJ }}</div>-->
                                            </el-form-item>
                                        </td>
                                        <td class="ty">
                                            <el-form-item prop="SYZJCPYSF" >
                                                <el-input style="width: 200px" :class="{colors:detail.SYZJCPYSF<0}" size="medium" class="inputright"  disabled v-model="detail.SYZJCPYSF" placeholder="成品油税费改革转移支付资金"></el-input>
<!--                                                <div class="fale" v-if='detail.SYZJCPYSF < 0'>0</div>-->
<!--                                                <div  class="fale" v-else>{{detail.SYZJCPYSF }}</div>-->
                                            </el-form-item>
                                        </td>
                                        <td class="ty">
                                            <el-form-item prop="SYZJZXZQ" >
                                                <el-input  size="medium" class="inputright"  :class="{colors:detail.SYZJZXZQ<0}" disabled v-model="detail.SYZJZXZQ" placeholder="专项债券"></el-input>
<!--                                                <div class="fale" v-if='detail.SYZJZXZQ < 0'>0</div>-->
<!--                                                <div  class="fale" v-else>{{detail.SYZJZXZQ }}</div>-->
                                            </el-form-item>
                                        </td>
                                        <td class="ty">
                                            <el-form-item prop="SYZJSSXZC" >
                                                <el-input  size="medium" class="inputright"  :class="{colors:detail.SYZJSSXZC<0}" disabled v-model="detail.SYZJSSXZC" placeholder="省市县自筹"></el-input>
<!--                                                <div class="fale" v-if='detail.SYZJSSXZC < 0'>0</div>-->
<!--                                                <div  class="fale" v-else>{{detail.SYZJSSXZC }}</div>-->
                                            </el-form-item>
                                        </td>
                                        <td class="ty">
                                            <el-form-item prop="SYZJYHDK" >
                                                <el-input  size="medium" class="inputright"  :class="{colors:detail.SYZJYHDK<0}" disabled v-model="detail.SYZJYHDK" placeholder="银行贷款"></el-input>
<!--                                                <div class="fale" v-if='detail.SYZJYHDK < 0'>0</div>-->
<!--                                                <div  class="fale" v-else>{{detail.SYZJYHDK }}</div>-->
                                            </el-form-item>
                                        </td>
                                        <td class="ty">
                                            <el-form-item prop="SYZJZQYZC" >
                                                <el-input  size="medium" class="inputright"  :class="{colors:detail.SYZJZQYZC<0}" disabled v-model="detail.SYZJZQYZC" placeholder="企业自筹"></el-input>
<!--                                                <div class="fale" v-if='detail.SYZJZQYZC < 0'>0</div>-->
<!--                                                <div  class="fale" v-else>{{detail.SYZJZQYZC }}</div>-->
                                            </el-form-item>
                                        </td>
                                        <td class="ty">
                                            <el-form-item prop="SYZJDFZC" >
                                                <el-input  size="medium" class="inputright"  :class="{colors:detail.SYZJDFZC<0}" disabled v-model="detail.SYZJDFZC" placeholder="地方自筹"></el-input>
<!--                                                <div class="fale" v-if='detail.SYZJDFZC < 0'>0</div>-->
<!--                                                <div  class="fale" v-else>{{detail.SYZJDFZC }}</div>-->
                                            </el-form-item>
                                        </td>
                                        <td class="ty">
                                            <el-form-item prop="SYZJJSNR" style="width:160px">
                                                <el-input  size="medium" class="inputcenter"  v-model="detail.SYZJJSNR" disabled placeholder="主要建设内容"></el-input>
                                            </el-form-item>
                                        </td>
                                        <!-- <td class="ty">
                                            <el-form-item prop="SYZJXZSCNL"   style="width:100px">
                                                <el-input  size="medium" class="inputcenter"   v-model="detail.SYZJXZSCNL" disabled placeholder="新增生产能力"></el-input>
                                            </el-form-item>
                                        </td> -->
                                    </tr>
                                </table>
                                <!-- <div style="display: flex;align-items: center" class="tztop">
                                    <el-form-item label="总投资"  prop="ZTZ">
                                        <el-input  placeholder="请输入总投资" v-model="detail.ZTZ" ></el-input>
                                    </el-form-item>
                                    <el-form-item style="margin-left: 20px" label="中央投资（车购税）"  prop="ZYTZ">
                                        <el-input  placeholder="中央投资（车购税）" v-model="detail.ZYTZ" ></el-input>
                                    </el-form-item>
                                    <el-form-item style="margin-left: 20px" label="省级补助资金"  prop="NextSJBZZJ">
                                        <el-input placeholder="省级补助资金" v-model="detail.NextSJBZZJ" ></el-input>
                                    </el-form-item>
                                </div>
                                <table style="text-align:center;margin-top: 20px"  class="littleTable">
                                    <tr>
                                        <td colspan="8" style="background: #F5F7FA">
                                            <div class="cont" style="text-align: center;width: 100%;font-weight: 600;color: #333">预计到{{nowYear}}年底累计完成投资</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="label2" style="width: 270px">合计</div>
                                        </td>
                                        <td >
                                            <div class="label2" style="width: 270px" >中央车购税</div>
                                        </td>
                                    </tr>
                                    <tr >
                                        <td class="ty">
                                            <el-form-item  prop="WCTZHJ" :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">
                                                <el-input style="width: 270px" size="medium" v-model="detail.WCTZHJ" placeholder="合计"></el-input>
                                            </el-form-item>
                                        </td>
                                        <td class="ty">
                                            <el-form-item prop="WCZYCGS" :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">
                                                <el-input style="width: 270px" size="medium" v-model="detail.WCZYCGS" placeholder="中央车购税"></el-input>
                                            </el-form-item>
                                        </td>
                                    </tr>
                                </table>
                                <table style="text-align:center;margin-top: 20px"  class="littleTable2">
                                    <tr>
                                        <td colspan="8" style="background: #f5f7fa">
                                            <div class="cont" style="text-align: center;width: 100%;font-weight: 600;color: #333">{{nowYear+1}}年建议计划</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="label2" >合计</div>
                                        </td>
                                        <td>
                                            <div class="label2" style="line-height: unset">提前下达批次 <br>申请车购税</div>
                                        </td>
                                        <td>
                                            <div class="label2" >第二批申请车购税</div>
                                        </td>
                                        <td>
                                            <div class="label2">中央车购税</div>
                                        </td>
                                        <td>
                                            <div class="label2">省级补助资金</div>
                                        </td>
                                        <td>
                                            <div class="label2" >地方（企业）自筹</div>
                                        </td>
                                        <td>
                                            <div class="label2">主要建设内容</div>
                                        </td>
                                        <td>
                                            <div class="label2">新增生产能力</div>
                                        </td>
                                    </tr>
                                    <tr >
                                        <td class="jhky">
                                            <el-form-item  prop="NextHJ" :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">
                                                <el-input size="medium" v-model="detail.NextHJ" placeholder="合计"></el-input>
                                            </el-form-item>
                                        </td>
                                        <td class="jhky">
                                            <el-form-item prop="SPCGS" :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">
                                                <el-input size="medium" v-model="detail.SPCGS" placeholder="提前下达批次申请车购税"></el-input>
                                            </el-form-item>
                                        </td>
                                        <td class="jhky">
                                            <el-form-item prop="EPCGS" :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">
                                                <el-input  size="medium" v-model="detail.EPCGS" placeholder="第二批申请车购税"></el-input>
                                            </el-form-item>
                                        </td>
                                        <td class="jhky">
                                            <el-form-item  prop="ZYCGS" :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">
                                                <el-input   size="medium" v-model="detail.ZYCGS" placeholder="中央车购税"></el-input>
                                            </el-form-item>
                                        </td>
                                        <td class="jhky">
                                            <el-form-item  prop="SJBZZJ" :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">
                                                <el-input  size="medium" v-model="detail.SJBZZJ" placeholder="省级补助资金"></el-input>
                                            </el-form-item>
                                        </td>
                                        <td class="jhky">
                                            <el-form-item  prop="DFZC" :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">
                                                <el-input  size="medium" v-model="detail.DFZC" placeholder="地方（企业）自筹"></el-input>
                                            </el-form-item>
                                        </td>
                                        <td class="jhky">
                                            <el-form-item  prop="ZYJSNR" :rules="[{ required: trstyle="width:160px"ue, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">
                                                <el-input  size="medium" v-model="detail.ZYJSNR" placeholder="主要建设内容"></el-input>
                                            </el-form-item>
                                        </td>
                                        <td class="jhky">
                                            <el-form-item  prop="XZSCNL" :rules="[{ required: tr  style="width:160px"ue, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">
                                                <el-input  size="medium" v-model="detail.XZSCNL" placeholder="新增生产能力"></el-input>
                                            </el-form-item>
                                        </td>
                                    </tr>
                                </table> -->
                            </div>

                        </el-tab-pane>
                        <el-tab-pane label="附件" name="3">

                            <el-row style="margin-bottom:10px">
                                <el-col :span="24" class="flexs-m">
                                    <div class="name"><span style="color: #f00;">*</span> 可研批复</div>
                                    <div class="value value1">
                                        <div class="name1">
                                            <el-form-item>
                                                <label class="fright">
                                                    <i aria-hidden="true" class="upload-demo cursor el-button el-button--primary el-button--small">上传</i>
                                                    <input accept="application/pdf" :disabled="!isLock" style="width: 72px" type="file" v-show="false" name="file" ref="file" id="fileInput" multiple="multiple" @change="onSubmit($event,'XMGKFJ')" />
                                                </label>
                                            </el-form-item>
                                        </div>
                                        <div class="fileWrap">
                                            <div class="file" v-for="(item,index) in detail.XMGKFJ" :key="index">
                                                                                <a target="_blank" :download="item.name" :href="baseUrl+item.url+item.name">
                                                    <img src="../../assets/images/ic-fj.png" alt=""><span style="margin-right: 6px;color: #409eff;overflow:hidden;text-overflow: ellipsis;max-width: 200px">{{item.name}} </span>
                                                                                    </a>
                                                <el-popconfirm title="是否删除此条信息?" @confirm="deleted(index,'XMGKFJ')">
                                                    <template #reference>
                                                        <i style="color: #666666" class="el-icon-delete" ></i>
                                                    </template>
                                                </el-popconfirm>
                                            </div>
                                        </div>
                                    </div>
                                </el-col>
                            </el-row>

<el-row style="margin-bottom:10px">
                                <el-col :span="24" class="flexs-m">
                                    <div class="name"><span style="color: #f00;" >*</span> 初步设计批复</div>
                                    <div class="value value1">
                                        <div class="name1">
                                            <el-form-item>
                                                <label class="fright">
                                                    <i aria-hidden="true" class="upload-demo cursor el-button el-button--primary el-button--small">上传</i>
                                                    <input accept="application/pdf" :disabled="!isLock" style="width: 72px" type="file" v-show="false" name="file" ref="file" id="fileInput" multiple="multiple" @change="onSubmit($event,'CBSJFJ')" />
                                                </label>
                                            </el-form-item>
                                        </div>
                                        <div class="fileWrap">
                                            <div class="file" v-for="(item,index) in detail.CBSJFJ" :key="index">
                                                                                <a target="_blank" :download="item.name" :href="baseUrl+item.url+item.name">
                                                    <img src="../../assets/images/ic-fj.png" alt=""><span style="margin-right: 6px;color: #409eff;overflow:hidden;text-overflow: ellipsis;max-width: 200px">{{item.name}} </span>
                                                                                    </a>
                                                <el-popconfirm title="是否删除此条信息?" @confirm="deleted(index,'CBSJFJ')">
                                                    <template #reference>
                                                        <i style="color: #666666" class="el-icon-delete" ></i>
                                                    </template>
                                                </el-popconfirm>
                                            </div>
                                        </div>
                                    </div>
                                </el-col>
                            </el-row>

<el-row style="margin-bottom:10px">
                                <el-col :span="24" class="flexs-m">
                                    <div class="name">
                                        <span style="color: #f00;">*</span>
                                        施工图批复</div>
                                    <div class="value value1">
                                        <div class="name1">
                                            <el-form-item>
                                                <label class="fright">
                                                    <i aria-hidden="true" class="upload-demo cursor el-button el-button--primary el-button--small">上传</i>
                                                    <input accept="application/pdf" :disabled="!isLock" style="width: 72px" type="file" v-show="false" name="file" ref="file" id="fileInput" multiple="multiple" @change="onSubmit($event,'SGTFJ')" />
                                                </label>
                                            </el-form-item>
                                        </div>
                                        <div class="fileWrap">
                                            <div class="file" v-for="(item,index) in detail.SGTFJ" :key="index">
                                                                                <a target="_blank" :download="item.name" :href="baseUrl+item.url+item.name">
                                                    <img src="../../assets/images/ic-fj.png" alt=""><span style="margin-right: 6px;color: #409eff;overflow:hidden;text-overflow: ellipsis;max-width: 200px">{{item.name}} </span>
                                                                                    </a>
                                                <el-popconfirm title="是否删除此条信息?" @confirm="deleted(index,'SGTFJ')">
                                                    <template #reference>
                                                        <i style="color: #666666" class="el-icon-delete" ></i>
                                                    </template>
                                                </el-popconfirm>
                                            </div>
                                        </div>
                                    </div>
                                </el-col>
                            </el-row>

                            <el-row style="margin-bottom:10px">
                                <el-col :span="24" class="flexs-m">
                                    <div class="name">
<!--                                        <span style="color: #f00;" v-if="!ssxzc">*</span>-->
                                        资金安排意见</div>
                                    <div class="value value1">
                                        <div class="name1">
                                            <el-form-item>
                                                <label class="fright">
                                                    <i aria-hidden="true" class="upload-demo cursor el-button el-button--primary el-button--small">上传</i>
                                                    <input accept="application/pdf" :disabled="!isLock" style="width: 72px" type="file" v-show="false" name="file" ref="file" id="fileInput" multiple="multiple" @change="onSubmit($event,'ZJAPYJFJ')" />
                                                </label>
                                            </el-form-item>
                                        </div>
                                        <div class="fileWrap">
                                            <div class="file" v-for="(item,index) in detail.ZJAPYJFJ" :key="index">
                                                                                <a target="_blank" :download="item.name" :href="baseUrl+item.url+item.name">
<!--                                                <div @click="exportFile(item.url,item.name)">-->
                                                    <img src="../../assets/images/ic-fj.png" alt=""><span style="margin-right: 6px;color: #409eff;overflow:hidden;text-overflow: ellipsis;max-width: 200px">{{item.name}} </span>
                                                                                    </a>
<!--                                                </div>-->
                                                <el-popconfirm title="是否删除此条信息?" @confirm="deleted(index,'ZJAPYJFJ')">
                                                    <template #reference>
                                                        <i style="color: #666666" class="el-icon-delete" ></i>
                                                    </template>
                                                </el-popconfirm>
                                            </div>
                                        </div>
                                    </div>
                                </el-col>
                            </el-row>
                            <el-row style="margin-bottom:10px">
                                <el-col :span="24" class="flexs-m">
                                    <div class="name"><span style="color: #f00;">*</span>承诺函</div>
                                    <div class="value value1">
                                        <div class="name1">
                                            <el-form-item>
                                                <label class="fright">
                                                    <i aria-hidden="true" class="upload-demo cursor el-button el-button--primary el-button--small">上传</i>
                                                    <input accept="application/pdf" :disabled="!isLock" style="width: 72px" type="file" v-show="false" name="file" ref="file" id="fileInput" multiple="multiple" @change="onSubmit($event,'DFZFZJCNHFJ')" />
                                                </label>
                                            </el-form-item>
                                        </div>
                                        <div class="fileWrap">
                                            <div class="file" v-for="(item,index) in detail.DFZFZJCNHFJ" :key="index">
                                                                                <a target="_blank" :download="item.name" :href="baseUrl+item.url+item.name">
<!--                                                <div @click="exportFile(item.url,item.name)">-->
                                                    <img src="../../assets/images/ic-fj.png" alt=""><span style="margin-right: 6px;color: #409eff;overflow:hidden;text-overflow: ellipsis;max-width: 200px">{{item.name}} </span>
                                                                                    </a>
<!--                                                </div>-->
                                                <el-popconfirm title="是否删除此条信息?" @confirm="deleted(index,'DFZFZJCNHFJ')">
                                                    <template #reference>
                                                        <i style="color: #666666" class="el-icon-delete" ></i>
                                                    </template>
                                                </el-popconfirm>
                                            </div>
                                        </div>

                                    </div>
                                </el-col>
                            </el-row>
                            <el-row style="margin-bottom:10px">
                                <el-col :span="24" class="flexs-m">
                                    <div class="name"><span style="color: #f00;" >*</span> 项目地理信息</div>
                                    <div class="value value1">
                                        <div class="name1">
                                            <el-form-item>
                                                <label class="fright">
                                                    <i aria-hidden="true" class="upload-demo cursor el-button el-button--primary el-button--small">上传</i>
                                                    <input  :disabled="!isLock" style="width: 72px" type="file" v-show="false" name="file" ref="file" id="fileInput" multiple="multiple" @change="onSubmit($event,'XMDZDTFJ')" />
                                                </label>
                                            </el-form-item>
                                        </div>
                                        <div class="fileWrap">
                                            <div class="file" v-for="(item,index) in detail.XMDZDTFJ" :key="index">
                                                                                <a target="_blank" :download="item.name" :href="baseUrl+item.url+item.name">
<!--                                                <div @click="exportFile(item.url,item.name)">-->
                                                    <img src="../../assets/images/ic-fj.png" alt=""><span style="margin-right: 6px;color: #409eff;overflow:hidden;text-overflow: ellipsis;max-width: 200px">{{item.name}} </span>
                                                                                    </a>
<!--                                                </div>-->
                                                <el-popconfirm title="是否删除此条信息?" @confirm="deleted(index,'XMDZDTFJ')">
                                                    <template #reference>
                                                        <i style="color: #666666" class="el-icon-delete" ></i>
                                                    </template>
                                                </el-popconfirm>
                                            </div>
                                        </div>

                                    </div>
                                </el-col>
                            </el-row>

                            <el-row style="margin-bottom:10px">
                                <el-col :span="24" class="flexs-m">
                                    <div class="name">
<!--                                        <span style="color: #f00;" v-if="!ssxzc">*</span>-->
                                        施工合同</div>
                                    <div class="value value1">
                                        <div class="name1">
                                            <el-form-item>
                                                <label class="fright">
                                                    <i aria-hidden="true" class="upload-demo cursor el-button el-button--primary el-button--small">上传</i>
                                                    <input accept="application/pdf" :disabled="!isLock" style="width: 72px" type="file" v-show="false" name="file" ref="file" id="fileInput" multiple="multiple" @change="onSubmit($event,'SGHTFJ')" />
                                                </label>
                                            </el-form-item>
                                        </div>
                                        <div class="fileWrap">
                                            <div class="file" v-for="(item,index) in detail.SGHTFJ" :key="index">
                                                                                <a target="_blank" :download="item.name" :href="baseUrl+item.url+item.name">
                                                    <img src="../../assets/images/ic-fj.png" alt=""><span style="margin-right: 6px;color: #409eff;overflow:hidden;text-overflow: ellipsis;max-width: 200px">{{item.name}} </span>
                                                                                    </a>
                                                <el-popconfirm title="是否删除此条信息?" @confirm="deleted(index,'SGHTFJ')">
                                                    <template #reference>
                                                        <i style="color: #666666" class="el-icon-delete" ></i>
                                                    </template>
                                                </el-popconfirm>
                                            </div>
                                        </div>
                                    </div>
                                </el-col>
                            </el-row>
                            <el-row style="margin-bottom:10px">
                                <el-col :span="24" class="flexs-m">
                                    <div class="name">
<!--                                        <span style="color: #f00;">*</span>-->
                                        施工许可</div>
                                    <div class="value value1">
                                        <div class="name1">
                                            <el-form-item>
                                                <label class="fright">
                                                    <i aria-hidden="true" class="upload-demo cursor el-button el-button--primary el-button--small">上传</i>
                                                    <input accept="application/pdf" :disabled="!isLock" style="width: 72px" type="file" v-show="false" name="file" ref="file" id="fileInput" multiple="multiple" @change="onSubmit($event,'SGXKFJ')" />
                                                </label>
                                            </el-form-item>
                                        </div>
                                        <div class="fileWrap">
                                            <div class="file" v-for="(item,index) in detail.SGXKFJ" :key="index">
                                                <a target="_blank" :download="item.name" :href="baseUrl+item.url+item.name">
                                                    <img src="../../assets/images/ic-fj.png" alt=""><span style="margin-right: 6px;color: #409eff;overflow:hidden;text-overflow: ellipsis;max-width: 200px">{{item.name}} </span>
                                                </a>
                                                <el-popconfirm title="是否删除此条信息?" @confirm="deleted(index,'SGXKFJ')">
                                                    <template #reference>
                                                        <i style="color: #666666" class="el-icon-delete" ></i>
                                                    </template>
                                                </el-popconfirm>
                                            </div>
                                        </div>
                                    </div>
                                </el-col>
                            </el-row>
                            <el-row style="margin-bottom:10px">
                                <el-col :span="24" class="flexs-m">
                                    <div class="name">
                                    <span style="color: #f00;" >*</span>
                                        施工中标通知书</div>
                                    <div class="value value1">
                                        <div class="name1">
                                            <el-form-item>
                                                <label class="fright">
                                                    <i aria-hidden="true" class="upload-demo cursor el-button el-button--primary el-button--small">上传</i>
                                                    <input accept="application/pdf" :disabled="!isLock" style="width: 72px" type="file" v-show="false" name="file" ref="file" id="fileInput" multiple="multiple" @change="onSubmit($event,'SGZBTZSFJ')" />
                                                </label>
                                            </el-form-item>
                                        </div>
                                        <div class="fileWrap">
                                            <div class="file" v-for="(item,index) in detail.SGZBTZSFJ" :key="index">
                                                <a target="_blank" :download="item.name" :href="baseUrl+item.url+item.name">
                                                    <img src="../../assets/images/ic-fj.png" alt=""><span style="margin-right: 6px;color: #409eff;overflow:hidden;text-overflow: ellipsis;max-width: 200px">{{item.name}} </span>
                                                </a>
                                                <el-popconfirm title="是否删除此条信息?" @confirm="deleted(index,'SGZBTZSFJ')">
                                                    <template #reference>
                                                        <i style="color: #666666" class="el-icon-delete" ></i>
                                                    </template>
                                                </el-popconfirm>
                                            </div>
                                        </div>
                                    </div>
                                </el-col>
                            </el-row>
                            <el-row style="margin-bottom:10px">
                                <el-col :span="24" class="flexs-m">
                                    <div class="name">其他</div>
                                    <div class="value value1">
                                        <div class="name1">
                                            <el-form-item>
                                                <label class="fright">
                                                    <i aria-hidden="true" class="upload-demo cursor el-button el-button--primary el-button--small">上传</i>
                                                    <input accept="application/pdf" :disabled="!isLock" style="width: 72px" type="file" v-show="false" name="file" ref="file" id="fileInput" multiple="multiple" @change="onSubmit($event,'QTFJ')" />
                                                </label>
                                            </el-form-item>
                                        </div>
                                        <div class="fileWrap">
                                            <div class="file" v-for="(item,index) in detail.QTFJ" :key="index">
                                                                                <a target="_blank" :download="item.name" :href="baseUrl+item.url+item.name">
                                                    <img src="../../assets/images/ic-fj.png" alt=""><span style="margin-right: 6px;color: #409eff;overflow:hidden;text-overflow: ellipsis;max-width: 200px">{{item.name}} </span>
                                                                                    </a>
                                                <el-popconfirm title="是否删除此条信息?" @confirm="deleted(index,'QTFJ')">
                                                    <template #reference>
                                                        <i style="color: #666666" class="el-icon-delete" ></i>
                                                    </template>
                                                </el-popconfirm>
                                            </div>
                                        </div>
                                    </div>
                                </el-col>
                            </el-row>


                        </el-tab-pane>
                    </el-tabs>


                    <div class="footer">
                        <!-- :loading="buttonLoading" -->
                        <el-button type="primary" @click="save" >保存</el-button>
                        <el-button @click="cancel">取消</el-button>
                    </div>
                </el-form>
            </el-drawer>
            <!--        十四五项目弹窗-->
            <el-drawer :visible.sync="assign" :title="detail.XMMC" size="80%">
                <el-form :inline="true" :model="formInline" class="demo-form-inline" style="display:flex">
                    <div class="search">
                        <el-form-item label="项目名称">
                            <el-input style="width: 300px" v-model="formInline.XMMC" placeholder="请输入项目名称"></el-input>
                        </el-form-item>
                    </div>
                    <el-form-item>
                        <el-button type="primary" @click="searchCompanyList">搜索</el-button>
                        <el-button type="primary" @click="confirmCompany">确定</el-button>
                    </el-form-item>
                </el-form>
                <template>
                    <el-table :header-cell-class-name="cellClass" @selection-change="selectCompany"  ref="multipleTable" :data="companyData"  border :default-sort="{ prop: 'SSDS', order: 'ascending' }" style="width: 100%">
                        <el-table-column fixed type="selection" width="55"  :selectable="checkSelectable"/>
                        <el-table-column prop="index"  label="序号"  align="center" type="index" :width="50" :index="1+30*(currentPage-1)"></el-table-column>
                        <el-table-column prop="SZDS"  label="所在地市" :width="100" sortable show-overflow-tooltip></el-table-column>
                        <el-table-column prop="SZX" label="所属县（市）" :width="150" sortable show-overflow-tooltip></el-table-column>
                        <el-table-column prop="XMFRDW" label="项目法人单位" align="center" show-overflow-tooltip :width="150" ></el-table-column>
                        <el-table-column prop="XMMC" label="项目名称" align="center" show-overflow-tooltip :width="320"></el-table-column>
                        <el-table-column prop="SSWGHXMMC"  align="center" label="对应部“十四五”交通运输专项建设规划项目名称" show-overflow-tooltip :width="320" ></el-table-column>
                        <el-table-column prop="QQGZJZ" label="前期工作进展" :width="400" align="center" show-overflow-tooltip>

                            <el-table-column  width="1" align="right"></el-table-column>
                            <el-table-column prop="JSGMGS" label="工可研" width="90" align="center">
                                <template slot-scope="scope">
                                    {{scope.row.GKBLZT == 0 ? '未开展' : scope.row.GKBLZT == '1' ? '办理中' : '已办结'}}
                                </template>
                            </el-table-column>
                            <el-table-column prop="JSGMGS" label="初步设计" width="90" align="center">
                                <template slot-scope="scope">
                                    {{scope.row.CBSJBLZT == 0 ? '未开展' : scope.row.CBSJBLZT == '1' ? '办理中' : '已办结'}}
                                </template>
                            </el-table-column>
                            <el-table-column prop="JSGMGS" label="施工图" width="90" align="center">
                                <template slot-scope="scope">
                                    {{scope.row.SGTBLZT == 0 ? '未开展' : scope.row.SGTBLZT == '1' ? '办理中' : '已办结'}}
                                </template>
                            </el-table-column>
                        </el-table-column>
                        <el-table-column prop="ZTZ" label="总投资(万元)" :width="120" align="center" sortable show-overflow-tooltip>
                            <template slot-scope="scope">
                                <div style="text-align: right">
                                    {{scope.row.ZTZ ? thousandBitSeparator(scope.row.ZTZ.toFixed(0) ): ''}}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="JSXZ" label="建设性质" align="center" show-overflow-tooltip :width="150"></el-table-column>

                        <el-table-column prop="XMXZ" label="项目性质" align="center" show-overflow-tooltip :width="150"></el-table-column>

                        <el-table-column label="建设规模" :width="150"  align="center">
                            <el-table-column prop="JSGMHJ" label="合计(公里)" width="90" align="right">
                                <template slot-scope="scope">
                                    {{scope.row.JSGMHJ ? scope.row.JSGMHJ.toFixed(2) : ''}}
                                </template>
                            </el-table-column>
                            <el-table-column prop="JSGMGS" label="高速(公里)" width="90" align="right">
                                <template slot-scope="scope">
                                    {{scope.row.JSGMGS ? scope.row.JSGMGS.toFixed(2) : ''}}
                                </template>
                            </el-table-column>
                            <el-table-column prop="JSGMYJ" label="一级(公里)" width="90" align="right">
                                <template slot-scope="scope">
                                    {{scope.row.JSGMYJ ? scope.row.JSGMYJ.toFixed(2) : ''}}
                                </template>
                            </el-table-column>
                            <el-table-column prop="JSGMEJ" label="二级(公里)" width="90" align="right">
                                <template slot-scope="scope">
                                    {{scope.row.JSGMEJ ? scope.row.JSGMEJ.toFixed(2) : ''}}
                                </template>
                            </el-table-column>
                            <el-table-column prop="JSGMSJ" label="三级(公里)" width="90" align="right">
                                <template slot-scope="scope">
                                    {{scope.row.JSGMSJ ? scope.row.JSGMSJ.toFixed(2) : ''}}
                                </template>
                            </el-table-column>
                            <el-table-column label="特大桥" width="50" align="center">
                                <el-table-column prop="JSGMTDQ" label="座数(座)" width="90" align="right"></el-table-column>
                                <el-table-column prop="JSGMTDQYM" label="延米数(米)" width="90" align="right"></el-table-column>
                            </el-table-column>
                            <el-table-column label="隧道" width="50" align="center">
                                <el-table-column prop="JSGMSD" label="座数(座)" width="90" align="right"></el-table-column>
                                <el-table-column prop="JSGMSDYM" label="延米数(米)" width="90" align="right"></el-table-column>
                            </el-table-column>
                        </el-table-column>
                        <el-table-column prop="QQGZFZR" label="前期工作负责人" :width="150" align="center" show-overflow-tooltip></el-table-column>
                        <el-table-column prop="LXDH" label="联系电话" :width="150" align="center" show-overflow-tooltip></el-table-column>
                    </el-table>
                    <el-pagination background @size-change="handleSizeChange1" @current-change="handleCurrentChange1" :current-page="currentPage1" :page-sizes="[10, 20, 30,40]" :page-size="pageSize1" layout="total, sizes, prev, pager, next, jumper" :total="total1"></el-pagination>
                    <!--                <el-pagination background @size-change="handleSizeChange1" @current-change="handleCurrentChange1" :current-page="currentPage1" :page-sizes="[30, 50, 100, 400]" :page-size="pageSize1" layout="total, sizes, prev, pager, next, jumper" :total="total1"></el-pagination>-->
                </template>
                <template #footer>
      <span class="dialog-footer">
        <el-button @click="assign = false">取消</el-button>
      </span>
                </template>
            </el-drawer>
        </div>
        <el-dialog
                :visible.sync="showMessage"
                title="提示"
                width="30%"
        >
            <span v-if="fillStatus">取消后需重新上报历史记录,是否确定退出?</span>
            <span v-else>当前页面未保存,是否确定退出?</span>
            <template #footer>
      <span class="dialog-footer">
        <el-button @click="showMessage = false">取消</el-button>
        <el-button type="primary" @click="closeEdit">确认</el-button>
      </span>
            </template>
        </el-dialog>
<!--        审核窗口-->
        <el-dialog
                :visible.sync="showCheck"
                title="审核"
                width="30%"
        >
            <!-- <div class="cont" style="text-align: left">实际下达资金(万元):</div> -->
            <!-- <el-input
                     type="number"
                    placeholder="请输入内容"
                    v-model="xdje">
            </el-input> -->
            <div class="cont" style="text-align: left">备注:</div>
            <el-input
                    type="textarea"
                    :rows="4"
                    placeholder="请输入内容"
                    v-model="remarks">
            </el-input>
            <template #footer>
      <span class="dialog-footer">
        <el-button @click="checkProject(false)">拒绝</el-button>
        <el-button type="primary" @click="checkProject(true)">通过</el-button>
      </span>
            </template>
        </el-dialog>


            <el-dialog
            title="提示"
            :visible.sync="dialogVisible123"
            width="30%">
            <span>是否确认上报项目？</span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible123 = false">取 消</el-button>
                <el-button type="primary" @click="checksBs();dialogVisible123 = false">确 定</el-button>
            </span>
            </el-dialog>

        <!--建设单位编辑弹窗-->
        <el-dialog :title="dialogTableVisibleData.RouteName" :visible.sync="dialogTableVisibleMap" width="100%" class="mapdialog mapdialog1">
            <PlanForm :key="parseInt(Math.random()*10000)" v-if="dialogTableVisibleMap"  :dialogData="dialogTableVisibleData" />
        </el-dialog>
        <div class="editWrap">
<!--            建设单位列表弹窗-->
            <el-drawer :visible.sync="frCompany" :title="'建设单位列表'" size="1200px">
                <el-form :inline="true" :model="frForm" class="demo-form-inline" style="margin-top: 42px">
                  <div class="search">
                      <el-form-item label="" prop="dwmc"  :rules="[
                                    { required: false, message:'请输入单位名称', trigger: 'blur' },
                                ]">
                          <el-input v-model="frForm.dwmc"  placeholder="请输入单位名称"></el-input>
                      </el-form-item>
                      <el-form-item label="">
                          <el-input v-model="frForm.xmzrr" placeholder="请输入项目负责人"></el-input>
                      </el-form-item>
                      <el-form-item label="">
                          <el-input v-model="frForm.xmlxr" placeholder="请输入项目联系人"></el-input>
                      </el-form-item>
                      <el-form-item>
                          <el-button type="primary" @click="getFRDW">查询</el-button>
                      </el-form-item>
                      <el-form-item>
                          <el-button type="primary" @click="conformXmlxr">确定</el-button>
                      </el-form-item>
                  </div>
                </el-form>
                <template>
                    <el-table
                            :row-class-name="tableRowClassName"
                            max-height="550"
                            border
                            ref="multipleTable1"
                            :data="frCompanyLists"
                            style="width: 100%"
                            @selection-change="selectCompany1"
                    >
                        <el-table-column type="selection" width="55"  :selectable="checkSelectable1"/>
                        <el-table-column  :filter-multiple="false"
                                          fixed prop="DWMC" label="单位名称"  show-overflow-tooltip></el-table-column>
                        <el-table-column fixed prop="XMZRR" label="项目负责人"  show-overflow-tooltip></el-table-column>
                        <el-table-column fixed width="150px" prop="XMZRRLXDH" label="负责人联系电话"  show-overflow-tooltip></el-table-column>
                        <el-table-column fixed prop="XMLXR" label="项目联系人"   show-overflow-tooltip></el-table-column>
                        <el-table-column fixed prop="XMLXRLXDH" label="联系人电话"  show-overflow-tooltip></el-table-column>
                        <!-- <el-table-column fixed label="操作"  show-overflow-tooltip >
                            <template slot-scope="scope">
                                <el-button type="text"  size="small" @click="edit(scope.row)">编辑</el-button>
                                <el-popconfirm  title="是否删除此条信息?"  @confirm="deletedCompany(scope.row)">
                                    <template #reference >
                                        <el-button style="margin-left: 12px" type="text" size="small" color="#d6000f">删除</el-button>
                                    </template>
                                </el-popconfirm>
                            </template>
                        </el-table-column> -->
                    </el-table>
                    <!--                <el-pagination background @size-change="handleSizeChange1" @current-change="handleCurrentChange1" :current-page="currentPage1" :page-sizes="[30, 50, 100, 400]" :page-size="pageSize1" layout="total, sizes, prev, pager, next, jumper" :total="total1"></el-pagination>-->
                </template>
                <template #footer>
      <span class="dialog-footer">
        <el-button @click="assign = false">取消</el-button>
      </span>
                </template>
            </el-drawer>
</div>
    </div>
</template>
<script>
    import PlanForm from "../frontMap/countrySideMap";
    import { exportData } from "../../js/util/export.js"; //导出表格
    import axios from "axios";
    import store from '../../store/index'
    import http from "../../api/http";
    import { BigNumber } from 'bignumber.js';

    export default {
        name: "countrySide",
        components: {PlanForm},
        data() {
            return {
                show3:false,
                ljxdhj:{
                    NJHTZSSXZC:0,
                    NJHTZSJBZZJ:0,
                    NJHTZHJ:0,
                    NJHTZZYTZ:0,
                    NJHTZCZZJ:0,
                    NJHTZCPYSF:0,
                    NJHTZZXZQ:0,
                    NJHTZYHDK:0,
                    NJHTZZQYZC:0,
                    NJHTZDFZC:0,
                },
                QQSGTXXX:{
                    hz:"",
                    nf:"",
                    jw:""
                },
                QQHZXXX:{
                    hz:"",
                    nf:"",
                    jw:""
                },
                dialogVisible123:false,
                dialogVisibleid:"",
                shsbloading:true,
                tjcount:0,
                buttonLoading:true,
                expParamsCity:[],
                XMFRDW:'',
                XMFRDWID:'',
                activeRoad: '高速公路',
                xdje:'',
                frCompanyList:[{WCTZHJ: '', WCZYCGS: '',}],
                remarks:'',
                showCheck: false,
                isLock: true,
                isEdit:true,
                assign: false,
                formInline:'',
                activeName:'0',
                dialogTableVisibleMap:false,
                dialogTableVisibleData:{},
                roleName:'',
                required: false,
                title:'新增建设单位',
                showAddModel: false,
                lineHeight:500,
                uploadLoading:false,
                loading:false,
                detail:{},
                showAllTZ:false,
                showMessage:false,
                baseUrl: "http://82.156.50.94:8002/",
                // baseUrl: "http://192.168.0.110:9991/",
                XMMC:'',
                addStatus: true,
                tableHeight:0, //表格高度
                /**查询条件 begin*/
                xmmc: "", //项目名称
                checkedJsxz: [], //建设性质
                checkedFlow:[],
                checkedXmlx: [], //项目类型
                checkedXmlxXMLB: [], //项目类型
                region: [""], //已选中行政区划
                /**查询条件 end*/
                checkList: [],
                flowList:[{value:'0',label:'待提交',disabled: false},{value:'4',label:'审核通过',disabled: false},{value:'-1,-2',label:'驳回',disabled: false},{value:'1',label:'市级待审核',disabled: false},{value:'2',label:'省级待审核',disabled: false},{value:'3',label:'报部待审核',disabled: false}],
                jsxzList: [{value:'新建',label:'新建',disabled: false},{value:'新改建',label:'新改建',disabled: false},{value:'改扩建',label:'改扩建',disabled: false},{value:'路面改造',label:'路面改造',disabled: false},{value:'其他',label:'其他',disabled: false}],
                xmlxList: [{value:'国家高速',label:'国家高速',disabled: false},{value:'地方高速',label:'地方高速',disabled: false},{value:'国道',label:'国道',disabled: false},{value:'省道',label:'省道',disabled: false}],
                currentPage: 1, //当前页
                currentPage1: 1, //当前页
                pageSize: 30, //每页记录数
                pageSize1: 10, //每页记录数
                superVip:false,
                regions: [
                    {
                        value: "",
                        label: "黑龙江省",
                    },
                ],
                regions2: [
                    {
                        value: "",
                        label: "黑龙江省",
                        children:[],
                    },
                ],
                tableData: [], //请求列表
                total: 0, //请求记录数
                searchAll:[],
                newAdd:false,
                showEdit:false,
                isAdmin: false,
                sortData: {
                    field: "XMXH",
                    sort: "asc",
                },
                companyData:[],
                total1:0,
                // nowYear:new Date().getFullYear(),
                nowYear:2025,
                ljxdtzlist:[],
                frCompanyLists:[],
                frCompany:false,
                frdw:'',
                frForm:{},
                chekfr:[{XMZRR:'',XMZRRLXDH:''}],
                confirmXMFR:[{XMZRR:'',XMZRRLXDH:''}],
                jhpc:"",
                multipleSelection: [],
                tjArr:{},
                SSWSSWGHXMID:"",
                SSWGHXMMC:"",
                QQID:"",
                QQMC:"",
                bcity:{
                    szs:"",
                    szx:"",
                },
                ssxzc:true,
                props: { multiple: true },
                PCyear:[2025,'第一批'],
                PCnumber:[],
                treeVal:['2025','第一批'],
                defaultParams: {
                    value: "value", //设置默认值value
                    label: "label", //设置显示的是什么
                    children: "children", //设置子元素数组是啥
                    multiple: false,//多选
                },
                treeData:[{
                    value:'2023',
                    label: '2023年投资建议计划',
                    children:[{
                        label: '第一批',
                        value:'第一批',
                    },{
                        label: '第一批大本计划',
                        value:'第一批大本计划',
                    },
                        {
                            label: '第二批',
                            value:'第二批',
                        },
                        {
                            label: '公路投资计划',
                            value:'公路投资计划',
                        }]
                },
                    {
                        value:'2024',
                        label: '2024年投资建议计划',
                        children:[{
                            label: '第一批',
                            value:'第一批',
                        },{
                            label: '第一批大本计划',
                            value:'第一批大本计划',
                        },
                            {
                                label: '第二批',
                                value:'第二批',
                            }]
                    },
                    {
                        value:'2025',
                        label: '2025年投资建议计划',
                        children:[{
                            label: '第一批',
                            value:'第一批',
                        },{
                            label: '第一批大本计划',
                            value:'第一批大本计划',
                        },
                            {
                                label: '第二批',
                                value:'第二批',
                            }]
                    },
                   ],
                defaultProps: {
                    children: 'children',
                    label: 'label'
                },
                fillStatus:false
            };
        },
        mounted() {
            var that = this
            // this.nowYear = new Date().getFullYear()
            let height = this.$refs.init.offsetHeight;
            setTimeout(() => {
                that.tableHeight = document.body.offsetHeight - height -180;
            }, 100);
            this.ljxdtzlist = [];
            // this.activeRoad = "高速公路";
            this.getTJ(2);
            this.getPc();
            this.changeRoad({name:this.activeRoad});
            this.getRegion();
            this.search();

            this.http.post('/api/Sys_User/getCurrentUserInfo',{}).then(res=>{
                this.roleName = res.data.roleName
                this.isAdmin = res.data.roleName == '超级管理员' ||  res.data.isMain  == 1 ? true : false
                if(res.data.userName == 'fanshuming' || res.data.userName == 'hc'|| res.data.userName == 'zxs'){
                    this.superVip = true
                }
            })
            // this.getCompanyList()
            // this.getFRDW()
        },
        activated(){
            console.log('进来了')
            if (localStorage.getItem('fillInfo')){
                console.log('有东西的')
                this.fillStatus = true
                this.addRoad()
                this.detail = JSON.parse(localStorage.getItem('fillInfo'))
                console.log(this.detail)
            }
        },
        computed:{

        },
        methods: {
            add(a, b) {
                a = BigNumber(a);
                b = BigNumber(b);
                return a.plus(b).toNumber();   //结果需要用toNumber转为普通数字
            },
            minus(a, b) {
                a = BigNumber(a);
                b = BigNumber(b);
                return a.minus(b).toNumber();
            },
            multiAdd(...params) {
                let data = BigNumber(0);
                for (let index = 0; index < params.length; index++) {
                    const element = BigNumber(params[index]);
                    data = data.plus(element);
                }
                return data.toNumber();
            },

    //调用

    changePc(val){
                console.log(val)
                // var a = []
                // val.map((item,index)=>{
                //     a.push(item[1])
                // })
                // this.PCyear = [...new Set(a)]
                this.PCyear =val
            },
            handleNodeClick(data1, data2, data3) {
                console.log(data1); //传递给 data 属性的数组中该节点所对应的对象
                // console.log(data2);//节点对应的 Node
                // console.log(data3);//节点组件本身
                /*
                getCurrentKey 	获取当前被选中节点的 key，使用此方法必须设置 node-key 属性，若没有节点被选中则返回 null
                */
                console.log(this.$refs.tree.getCurrentKey());
            },
            handleCheckChange(nodeObj, SelectedObj) {
                console.log(nodeObj, SelectedObj)
                // console.log(this.$refs.tree.getCheckedNodes(true,false),'node');
                // console.log(this.$refs.tree.getCheckedKeys(false),'key');
                // console.log(this.$refs.tree.getCurrentKey());
                // console.log(this.$refs.tree.getHalfCheckedKeys());

                // if(checked){
                //     console.log(data)
                // }
            },
            changeXLXZ(index){
                console.log(index)
                // if (index === '普通省道'){
                //     this.ssxzc = true
                // }else{
                //     this.ssxzc = false
                // }
            },
            setDSQX(row){
                if(row.SZS==row.SZX || !row.SZX){
                    return row.SZS
                }
                return row.SZS+'/'+row.SZX
            },
            getPc(){
                this.http.post('/api/Sys_Dictionary/GetVueDictionary',['jhtb_pc']).then(res=>{
                    // console.log(res);
                    if(res.length>0){
                        this.jhpc = res[0].data[0].value
                    }
                })
            },
            getTJ(type){
                this.http.post('/api/Plan_filling/getPageTotal', this.postData()).then(res=>{
                    if(type==2){
                        this.bcity = {
                            szs:res.fillStatistics.szs,
                            szx:res.fillStatistics.szx
                        }
                    }else{
                        res.fillStatistics.count = res.fillStatistics.wtg+res.fillStatistics.ysh+res.fillStatistics.ytj;
                        this.tjArr = res;
                    }
                })
                this.http.post('/api/Plan_filling/GetAreaTotal', this.postDataDs()).then(res=>{
                    setTimeout(() => {
                        let arrResult = res.areaStatistics.reduce((pre,cur) =>{
                        pre[cur.xzmc] = cur in pre?pre[cur.xzmc]+ cur.count :cur.count;
                        return pre;
                    },{});
                    this.regions2[0].children.map(r=>{
                        r.label = r.value+"（"+(arrResult[r.value] !== undefined?arrResult[r.value]:'0')+"）"
                    })
                    }, 500);
                })
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
            },
            tableRowClassName(row, index) {
                return this.checkSelectable1(row.row) ? "" : "hidden";
            },
            conformXmlxr(){
                if (this.chekfr.length != 0){

                this.confirmXMFR =  this.chekfr
                this.XMFRDW = this.confirmXMFR[0].DWMC
                this.XMFRDWID = this.confirmXMFR[0].ID
                }else{
                    this.confirmXMFR=[{XMZRR:'',XMZRRLXDH:''}],

                        this.XMFRDW = ''
                    this.XMFRDWID = ''
                }
                this.frCompany = false
            },
            checkSelectable1(row) {
                if(!row.XMLXR){
                    row.XMLXR = ''
                }
                if (this.frForm.dwmc && row.DWMC.indexOf(this.frForm.dwmc) < 0 ||
                    this.frForm.xmzrr && row.XMZRR.indexOf(this.frForm.xmzrr) < 0 ||
                    this.frForm.xmlxr && row.XMLXR.indexOf(this.frForm.xmlxr) < 0 ) {
                    return false;
                } else {
                    return true;
                }
            },
            async selectCompany1(val){
                if(val.length > 1){
                    this.$refs.multipleTable1.clearSelection()
                    this.$refs.multipleTable1.toggleRowSelection(val.pop())
                }else{
                    this.chekfr = val;
                }
            },
            getFRDW(){
                var that =this
                this.frCompanyLists = []
                this.http.post('/api/Plan_buildcompany/GetPageData', {rows:1000}).then(res=>{
                    // console.log(res)
                    res.rows.map((item,index)=>{
                        // if (item.XMLX == 'Road'){
                            this.frCompanyLists.push(item)
                        // }
                    })

                    if(this.addStatus){
                        if ( this.confirmXMFR.length != 0 && this.confirmXMFR[0].XMZRR == ''){
                            this.$nextTick(()=>{
                                this.$refs.multipleTable1.clearSelection()
                            })
                        }

                    }else {
                        if (this.confirmXMFR.length != 0 && this.confirmXMFR[0].XMZRR != ''){
                            setTimeout(()=> {
                                this.$nextTick(() => {
                                    that.$refs.multipleTable1.toggleRowSelection(that.frCompanyLists.find((item) => {
                                        if (item.ID == that.XMFRDWID) {
                                            return item
                                        }
                                    }))
                                }, 1000)
                            })
                        }
                    }
                })
                // console.log(this.frCompanyLists)
            },
            showFr(){
                var that = this
                // if(!this.isAdmin){
                //     this.$message.warning('暂无此权限')
                //     return false
                // }
                this.getFRDW()
                this.frCompany = true
                if(this.addStatus){
                    if ( this.confirmXMFR.length != 0 && this.confirmXMFR[0].XMZRR == ''){
                        this.$nextTick(()=>{
                            this.$refs.multipleTable1.clearSelection()
                        })
                    }else{
                        setTimeout(()=> {
                            this.$nextTick(() => {
                                that.$refs.multipleTable1.toggleRowSelection(that.frCompanyLists.find((item) => {
                                    if (item.ID == that.XMFRDWID) {
                                        return item
                                    }
                                }))
                            })
                        },1000)

                    }

                }else {
                    if (this.confirmXMFR.length != 0 && this.confirmXMFR[0].XMZRR != ''){
                        setTimeout(()=> {
                            this.$nextTick(() => {
                                that.$refs.multipleTable1.toggleRowSelection(that.frCompanyLists.find((item) => {
                                    if (item.ID == that.XMFRDWID) {
                                        return item
                                    }
                                }))
                            }, 1000)
                        })
                    }
                }
            },
            changeRoad(tab, event){
                this.currentPage = 1
                this.checkedXmlxXMLB = [tab.name]
                this.search()
            },
            //所在市去重
            unique(arr) {
                return arr;
                // var a = JSON.parse(arr)
                // console.log(a)
                // var city = []
                // a.map((item)=>{
                //     city.push(item[0])
                // })
                // city =  Array.from(new Set(city))
                // return city =  city.toString()
                // // return arr.filter((arr) => !res.has(arr.projectNo) && res.set(arr.projectNo, 1));
            },
            unique1(arr){
                return arr;
                // var a = JSON.parse(arr)
                // var city = []
                // a.map((item)=>{
                //     city.push(item[1])
                // })
                // return city =  city.toString()
            },
            changeAddress(val){
              console.log(val)
            },
            changeAddressCity(val){
                console.log(val);
            },
            checkPro(row){
                this.showCheck = true
                this.id = row.Id
                // console.log(this.id)
            },
            checkProject(val){
                if(this.shsbloading == false){
                    return false;
                }

                console.log(val)
                if (val == false && this.remarks == ''){
                    this.$message.error('请输入拒绝原因')
                    return false
                }
                this.shsbloading = false;
                this.http.post('/api/Plan_filling/AuditFilling?id='+this.id+'&isPassed='+val+'&remarks='+this.remarks,{id:this.id,remarks:this.remarks,isPassed:val,xdje:this.xdje}).then(res=>{
                    this.shsbloading = true;
                    if(res.status){
                        this.$message.success(res.message)
                        this.remarks = ''
                        this.showCheck = false
                        this.search()
                    }else{
                        this.$message.error(res.message)
                    }
                })
            },
            checkSelectable(row) {
                if (this.formInline.dwmc && row.dwmc.indexOf(this.formInline.dwmc) < 0) {
                    return false;
                } else {
                    return true;
                }
            },
            //选择建设单位
            selectCompany(val){
                var that = this
                if(val.length > 1){
                    this.$refs.multipleTable.clearSelection()
                    this.$refs.multipleTable.toggleRowSelection(val.pop())
                }else{
                    this.chek = val;

                }
            },
            searchCompanyList(){
                this.getCompanyList(1)
            },
            //获取前期项目详情
            getQQDetail(id){
                this.http.get('/api/Plan_high_national_early_node/GetAllNode?id='+id,{}).then(res=>{
                    this.detail.XMGKFJ = this.dealFj(res.data[10].fj),
                        this.detail.CBSJFJ = this.dealFj(res.data[24].fj),
                        this.detail.SGHTFJ = this.dealFj(this.detail.SGHTFJ),
                        // this.detail.JYJHSQFJ = this.dealFj(this.detail.JYJHSQFJ)
                        this.detail.SGTFJ = this.dealFj(res.data[28].fj)
                    this.detail.SGXKFJ = this.dealFj(this.detail.SGXKFJ)
                    this.detail.SGZBTZSFJ = this.dealFj(this.detail.SGZBTZSFJ)
                    this.detail.ZJAPYJFJ =this.dealFj(this.detail.ZJAPYJFJ)
                    this.detail.DFZFZJCNHFJ =this.dealFj(this.detail.DFZFZJCNHFJ)
                    this.detail.XMDZDTFJ =this.dealFj(this.detail.XMDZDTFJ)
                })
                this.http.post('/api/Plan_high_national_early/getItemById?id='+id,{}).then(res=>{
                    this.$set(this.detail, 'JSGMHJ', res.data.jsgmhj)
                    this.$set(this.detail, 'JSGMYJ', res.data.jsgmyj)
                    this.$set(this.detail, 'JSGMEJ', res.data.jsgmej)
                    this.$set(this.detail, 'JSGMSJ', res.data.jsgmsj)
                    this.$set(this.detail, 'JSGMSI', res.data.jsgmsi)
                    this.$set(this.detail, 'JSGMGS', res.data.jsgmgs)
                    this.$set(this.detail, 'LXBH', res.data.lxbh)
                    this.$set(this.detail, 'XMLB', res.data.qqgllx)
                    this.$set(this.detail, 'JGZRDW', res.data.xmfrdw)
                    this.$set(this.detail, 'KGN', res.data.kgn)
                    this.$set(this.detail, 'WGN', res.data.wgn)
                    this.$set(this.detail, 'XMMC', res.data.xmmc)
                    this.$set(this.detail, 'JSXZ', res.data.jsxz)
                    this.$set(this.detail, 'JHZZJHJ', res.data.ztz)
                    // this.setDSQX()
                    // this.detail.jsxz = this.detail.jsxz ? this.detail.jsxz.split(';') : []
                    // this.detail.jsnr = this.detail.jsnr ? this.detail.jsnr.split(';') : []
                    // this.totalSqu = res.data.ydhj
                    // this.XMMC = res.data.sswghxmmc
                    // this.SSWSSWGHXMID = res.data.sswghxmid
                    // this.XMFRDW = res.data.xmfrdw
                    // this.XMFRDWID = res.data.xmfrdwid
                    // this.chekfr = [{ID:res.data.xmfrdwid,DWMC: res.data.xmfrdw,XMZRR: res.data.qqgzfzr,XMZRRLXDH:res.data.lxdh}]
                    // this.confirmXMFR = [{ID:res.data.xmfrdwid,DWMC: res.data.xmfrdw,XMZRR: res.data.qqgzfzr,XMZRRLXDH:res.data.lxdh}]

                })
            },
            confirmCompany(){
                this.confirmSSW = JSON.parse(JSON.stringify(this.chek))
                // console.log(this.confirmSSW,123);

                if (this.chek.length != 0 ){
                    this.getItemByQQID(this.confirmSSW);
                    this.XMMC = this.chek[0].XMMC
                    this.SSWSSWGHXMID =this.chek[0].SSWGHXMID
                    this.SSWGHXMMC =this.chek[0].SSWGHXMMC
                    this.QQID =this.chek[0].ID
                    this.getQQDetail(this.QQID)
                    this.detail.SSWXMMC = this.chek[0].SSWGHXMMC
                    this.detail.SSWXMID = this.chek[0].SSWGHXMID
                }else{
                    this.XMMC = ''
                    this.SSWSSWGHXMID = ''
                    this.SSWGHXMMC = ''
                    this.QQID = ''
                    this.detail.SSWXMMC = ""
                    this.detail.LJWCTZHJ = '';//添加累计完成投资
                    this.detail.LJWCTZZYTZ='';
                    this.ljxdtzlist = [];
                }
                this.assign = false
            },
            getItemByQQID(row){//根据前期ID获取历年计划
                if(row.length>0){
                    var id = row[0].ID;
                    if(id){
                        this.http.post('/api/Plan_filling_history/GetItemByQQID?id='+id, {}).then(res=> {
                            if(res.length>0){
                                var a = []
                                res.map((item,index)=>{
                                    if(item.NDPC != '2023年第一批'){
                                        a.push(item)
                                    }
                                })
                                var data = a
                                let lastdata = data[data.length-1]
                                // this.detail.LJWCTZHJ = lastdata.LJWCTZ;//添加累计完成投资
                                // this.detail.LJWCTZZYTZ=lastdata.LJWCZYCGS;
                                this.detail.LJWCTZCPYSF = lastdata.LJWCTZCPYSF
                                this.detail.LJWCTZCZZJ = lastdata.LJWCTZCZZJ
                                this.detail.LJWCTZDFZC = lastdata.LJWCTZDFZC
                                this.detail.LJWCTZHJ = lastdata.LJWCTZHJ
                                this.detail.LJWCTZJSNR = lastdata.LJWCTZJSNR
                                this.detail.LJWCTZQYZC = lastdata.LJWCTZQYZC
                                this.detail.LJWCTZXZSCNL = lastdata.LJWCTZXZSCNL
                                this.detail.LJWCTZYHDK = lastdata.LJWCTZYHDK
                                this.detail.LJWCTZZXZQ = lastdata.LJWCTZZXZQ
                                this.detail.LJWCTZZYTZ = lastdata.LJWCTZZYTZ
                                this.setZjValue(res)
                            }
                            // this.ljxdtzlist = a
                            this.ljxdtzlist = this.sortByKey(data,"XH",'asc')
                            console.log(this.ljxdtzlist.length,'this.ljxdtzlist')
                            if(this.ljxdtzlist.length >0){
                                this.showAllTZ = false
                            }else{
                                this.showAllTZ = true
                            }
                        })
                    }
                }
            },
            setZjValue(res){
                    var hj = 0;
                    var cgs = 0;
                    var cz = 0;
                    var cpy = 0;
                    var zx = 0;
                    var yh = 0;
                    var qy = 0;
                    var df = 0;
                    var zc = 0;
                    var sb = 0;
                    res.map(r=>{
                        hj+=this.moneyToNumFiled(r.NJHTZHJ||0);
                        cgs+=this.moneyToNumFiled(r.NJHTZZYTZ||0);
                        cz+=this.moneyToNumFiled(r.NJHTZCZZJ||0);
                        cpy+=this.moneyToNumFiled(r.NJHTZCPYSF||0);
                        zx+=this.moneyToNumFiled(r.NJHTZZXZQ||0);
                        yh+=this.moneyToNumFiled(r.NJHTZYHDK||0);
                        qy+=this.moneyToNumFiled(r.NJHTZZQYZC||0);
                        df+=this.moneyToNumFiled(r.NJHTZDFZC||0);
                        zc+=this.moneyToNumFiled(r.NJHTZSSXZC||0);
                        sb+=this.moneyToNumFiled(r.NJHTZSJBZZJ||0);
                    })

                    this.ljxdhj.NJHTZHJ = hj;
                    this.ljxdhj.NJHTZZYTZ = cgs;
                    this.ljxdhj.NJHTZCZZJ = cz;
                    this.ljxdhj.NJHTZCPYSF = cpy;
                    this.ljxdhj.NJHTZZXZQ = zx;
                    this.ljxdhj.NJHTZSSXZC = zc;
                    this.ljxdhj.NJHTZYHDK = yh;
                    this.ljxdhj.NJHTZZQYZC = qy;
                    this.ljxdhj.NJHTZDFZC = df;
                    this.ljxdhj.NJHTZSJBZZJ = sb;
                    console.log(this.detail.JHZZJSSXZC,zc,this.detail.NJHTZSSXZC,'嘎嘎嘎嘎')
                    this.detail.SYZJHJ = (parseFloat(this.detail.JHZZJHJ||0)-hj-parseFloat(this.detail.NJHTZHJ||0)).toFixed(2);
                    this.detail.SYZJZYTZ =  this.setNumDefault(this.detail.JHZZJZYTZ)-cgs-this.setNumDefault(this.detail.NJHTZZYTZ);
                    this.detail.SYZJCZZJ = this.setNumDefault(this.detail.JHZZJCZZJ)-cz-this.setNumDefault(this.detail.NJHTZCZZJ);
                    this.detail.SYZJCPYSF = this.setNumDefault(this.detail.JHZZJCPYSF)-cpy-this.setNumDefault(this.detail.NJHTZCPYSF);
                    this.detail.SYZJZXZQ = this.setNumDefault(this.detail.JHZZJZXZQ)-zx-this.setNumDefault(this.detail.NJHTZZXZQ);
                    this.detail.SYZJSSXZC =this.setNumDefault(this.detail.JHZZJSSXZC)-zc-this.setNumDefault(this.detail.NJHTZSSXZC);
                    this.detail.SYZJYHDK = this.setNumDefault(this.detail.JHZZJYHDK)-yh-this.setNumDefault(this.detail.NJHTZYHDK);
                    this.detail.SYZJZQYZC = this.setNumDefault(this.detail.JHZZJQYZC)-qy-this.setNumDefault(this.detail.NJHTZZQYZC);
                    this.detail.SYZJDFZC = (this.setNumDefault(this.detail.JHZZJDFZC)-df-this.setNumDefault(this.detail.NJHTZDFZC)).toFixed(2);
                    this.detail.SYZJSJBZZJ = this.setNumDefault(this.detail.SJBZZJ)-sb-this.setNumDefault(this.detail.NJHTZSJBZZJ);

                if(this.detail.JHZZJDFZC == null){
                    this.detail.JHZZJDFZC  =df
                }
            },
            Subtr(arg1,arg2){
                var r1,r2,m,n;
                try{r1=arg1.toString().split(".")[1].length}catch(e){r1=0}
                try{r2=arg2.toString().split(".")[1].length}catch(e){r2=0}
                m=Math.pow(10,Math.max(r1,r2));
                n=(r1>=r2)?r1:r2;
                console.log(n)
                return ((arg1*m-arg2*m)/m);
            },
            sortByKey(array,key,type){
                return array.sort(function(a,b){
                    var x = parseFloat(a[key]);
                    var y = parseFloat(b[key]);
                    if(type == 'desc'){
                        return((x>y)?-1:((x<y)?1:0));
                    }else{
                        return((x<y)?-1:((x>y)?1:0));
                    }
                })
            },
            openTable(){
                if(!this.isLock || !this.isEdit){
                    return
                }
                var that = this
                this.assign = true
                this.formInline = {}
                this.currentPage1 = 1
                this.getCompanyList()
            },
            getCompanyList(page){
                var that = this
                this.http.post('/api/Plan_high_national_early/GetData',  this.postData1(page)).then(res=> {
                    this.companyData = res.rows
                    this.total1 = res.total
                    this.$nextTick(() => {
                        that.$refs.multipleTable.toggleRowSelection(that.companyData.find((item) => {
                                if (item.XMMC == that.detail.QQMC){
                                    return item
                                }
                            }),
                            true);
                    })
                })
            },
            postData1(page){
                //项目名称
                var query_xmmc = {
                    Name: "XMMC",
                    Value: this.formInline.XMMC,
                    DisplayType: "like",
                };
                if(page){
                    this.currentPage1 = page
                }
                var postData = {
                    page: this.currentPage1,
                    rows: this.pageSize1,
                    Sort: "DSBM",
                    Order: "asc",
                    wheres: JSON.stringify([
                        query_xmmc
                    ]),
                };
                if(!this.addStatus){
                    postData.value = this.detail.id
                }
                return postData;
            },
            showMaps(row) {
                this.http.post('/api/Ncgl_flag/getItemById?id='+row.ID,{}).then(res=>{
                    // if(res.status){
                    row.XZFW = res.XZFW
                    row.BaseInfoID = res.BaseInfoID ? res.BaseInfoID : row.ID
                    row.QQID = res.ID!='00000000-0000-0000-0000-000000000000' ? res.ID : row.ID
                    this.dialogTableVisibleData = row;
                    this.dialogTableVisibleMap = true;
                    // }else{
                    //     this.$message.error(res.message)
                    // }
                })
            },
            thousandBitSeparator(num) {
                var reg=/\d{1,3}(?=(\d{3})+$)/g;
                return (num + '').replace(reg, '$&,');
            },
            changeSort(val) {
                if (val.order) {
                    this.sortData = {
                        field: val.prop,
                        sort: val.order == "ascending" ? "asc" : "desc",
                    };
                    if (val.prop == "SZDS") {
                        this.sortData.field = "DSBM";
                    }
                    if (val.prop == "SZX") {
                        this.sortData.field = "QXBM";
                    }
                } else {
                    this.sortData = {
                        field: "XMXH",
                        sort: "asc",
                    };
                }
                this.tableData = [];
                this.search();
            },

            closeDrawer(){
                this.$refs.detailForm.resetFields()
            },
            closeDrawer2(){

                console.log(this.isLock);
                if(this.isLock == false){
                    this.$refs.detailForm.resetFields()
                    this.showAddModel = false;
                }else{
                    this.showMessage = true
                }
            },
            cellClass(row){
                if (row.columnIndex === 0) {
                    return 'DisableSelection'
                }
            },
            checkZZS  (rule, value, callback)  {
                if (!value) {
                    return callback(new Error('所填项不能为空'));
                }
                var reg =  /^[+]{0,1}(\d+)$/
                setTimeout(() => {
                    if (!reg.test(value)) {
                        callback(new Error('请输入数字值'));
                    } else {
                        callback();
                    }
                }, 0);
            },
            checkQQXXX(rule, value, callback)  {
                console.log(this.QQHZXXX,value,rule)
                var hz = "";
                var nf = "";
                var jw = "";
                var ts = "";
                if(value  == 'QQHZXXX'){
                    hz = this.QQHZXXX.hz
                    nf = this.QQHZXXX.nf
                    jw = this.QQHZXXX.jw
                    ts = "请输入工可或核准批复文号";
                }
                if(value  == 'QQSGTXXX'){
                    hz = this.QQSGTXXX.hz
                    nf = this.QQSGTXXX.nf
                    jw = this.QQSGTXXX.jw
                    ts = "请输入初设或施工图批复文号";
                }
                // console.log(nf);
                var reg = new RegExp("[\u4E00-\u9FA5]+$");
                if(!hz || !nf || !jw){
                    callback(new Error(ts));
                }else if(nf.length != 4){
                    // nf = nf.slice(0,4)
                    callback(new Error('请输入正确的年份'));
                }else if (reg.test(hz) == false) {
                    callback(new Error("单位只能输入中文"));
                }else{
                    console.log(123123123123);
                    callback();
                }

                // if(this.QQHZXXX[0])

            },

            checkNumFs  (rule, value, callback)  {
                if (value < 0) {
                    callback();
                    // callback(new Error('不能为负数'));
                }else{
                    callback();
                }
            },
            checkNum  (rule, value, callback)  {
                console.log(value)
                if (!value) {
                    callback(new Error('请输入数字值'));
                }
                // if (value < 0) {
                //     callback(new Error('不能为负数'));
                // }
                var reg =  /^\d+(\.\d+)?$/
                setTimeout(() => {
                    if (value && !reg.test(value)) {
                        callback(new Error('请输入数字值'));
                    } else {
                        callback();
                    }
                }, 0);
            },
            checkEdit(rule, value, callback){
                if (this.newAdd == false){
                    callback();
                }else{
                    if (!value) {
                        callback(new Error('请输入当前内容'));
                    }else{
                        callback();
                    }
                }
            },
            checkNull(rule, value, callback){
                // if (this.newAdd == false){
                //
                //     return false
                // }
                if (!value) {
                    callback(new Error('请输入当前内容'));
                }else{
                    callback();
                }
            },
            download(url, fileName) {
                //下载导出的文件
                let xmlResquest = new XMLHttpRequest();
                xmlResquest.open("GET", url, true);
                xmlResquest.setRequestHeader("Content-type", "application/json");
                xmlResquest.setRequestHeader(
                    "Authorization",
                    store.getters.getToken()
                );
                let elink = document.createElement("a");
                xmlResquest.responseType = "blob";
                xmlResquest.onload = function(oEvent) {
                    if (xmlResquest.status != 200) {
                        this.$error("下载文件出错了..");
                        return;
                    }
                    let content = xmlResquest.response;
                    elink.download = fileName; //+".xlsx";
                    // elink.style.display = "none";
                    let blob = new Blob([content]);
                    elink.href = URL.createObjectURL(blob);
                    // document.body.appendChild(elink);
                    elink.click();
                    //  document.body.removeChild(elink);
                };
                xmlResquest.send();
            },
            downLoadZip(row){
                var that = this
                this.http.get('/api/Plan_high_national_early/getFilePackage?id='+row.ID,{}).then(res=>{
                    if(!res.status){
                        this.$message.error(res.message)
                        return false
                    }
                    let path = "/api/" + 'Plan_high_national_early' + "/DownLoadFile";
                    path = path[0] == "/" ? path.substring(1) : path;
                    var name = row.XMMC == '' || row.XMMC ==null ? row.SSWGHXMMC :row.XMMC
                    this.download(
                        that.baseUrl + path + "?path=" + res.data,
                        name+'.zip'
                    );
                })
            },
            //搜索条件
            searchData(){
                var searchData = {
                    page: this.currentPage1,
                    rows: this.pageSize1,
                    wheres:JSON.stringify([{Name:'XMLX',Value:'Road',DisplayType:'Equal'}]),
                };
                return searchData
            },
            confirmChoose(){

            },
            addRoad(){
                console.log(this.tjArr,123);
                var that = this
                this.showAllTZ = true
                this.buttonLoading = false;
                this.addStatus = true
                this.isLock = true
                this.isEdit = true
                this.XMMC = ''
                this.currentPage1 = 1
                this.showAddModel = true
                this.ljxdtzlist = [];
                this.QQSGTXXX={
                    hz:"",
                    nf:"",
                    jw:""
                },
                this.QQHZXXX={
                    hz:"",
                    nf:"",
                    jw:""
                },
                this.detail = {
                    // JYJHSQFJ:[],
                    XMGKFJ:[],
                    CBSJFJ:[],
                    SGTFJ:[],
                    SGXKFJ:[],
                    SGZBTZSFJ:[],
                    ZJAPYJFJ:[],
                    DFZFZJCNHFJ:[],
                    XMDZDTFJ:[],
                    SGHTFJ:[],
                    QTFJ:[],
                    JHZZJHJ:"",
                    LJWCTZHJ:"",
                    NJHTZHJ:"",
                    SYZJHJ:"",
                    SYZJSJBZZJ:"",
                    SYZJZYTZ:"",
                    SYZJCZZJ:"",
                    SYZJCPYSF:"",
                    SYZJZXZQ:"",
                    SYZJSSXZC:"",
                    SYZJYHDK:"",
                    SYZJZQYZC:"",
                    SYZJDFZC:"",
                    JHZZJZYTZ:"",
                    JHZZJCZZJ:"",
                    JHZZJCPYSF:"",
                    JHZZJZXZQ:"",
                    JHZZJSSXZC:'',
                    JHZZJYHDK:"",
                    JHZZJQYZC:"",
                    JHZZJDFZC:"",
                    LJWCTZZYTZ:"",
                    LJXDCZZJ:"",
                    LJXDCPYSF:"",
                    LJWCTZZXZQ:"",
                    LJWCTZYHDK:"",
                    LJWCTZQYZC:"",
                    LJXDDFZC:"",
                    NJHTZZYTZ:"",
                    NJHTZCZZJ:"",
                    NJHTZCPYSF:"",
                    NJHTZZXZQ:"",
                    NJHTZYHDK:"",
                    NJHTZZQYZC:"",
                    NJHTZDFZC:"",
                    JHZZJJSNR:"",
                    JHZZJXZSCNL:"",
                    LJWCTZJSNR:"",
                    LJWCTZXZSCNL:"",
                    NJHTZJSNR:"",
                    NJHTZXZSCNL:"",
                    SYZJJSNR:"",
                    JHPC:this.jhpc,
                    XMLB:this.activeRoad,
                    SZS:this.bcity.szs,
                    SZX:this.bcity.szx,
                }
                if(this.detail.XMLB == '普通省道'){
                    this.ssxzc = true
                }else{
                    this.ssxzc = false
                }
                if(this.bcity.szs == this.bcity.szx){
                    this.detail.SZX = "";
                }
                console.log(this.detail);
            },
            //退出编辑
            closeEdit(){
                localStorage.removeItem('fillInfo')
                this.showMessage = false
                this.showEditModel = false
                this.showAddModel = false
                this.detail = {}
            },
            //删除图片
            deleted(index,type){
                console.log(this.detail[type])
                this.detail[type].splice(index,1)
            },
            setTimes(time) {
                if (!time) return time;
                let dt = new Date(time);
                let yyyy = dt.getFullYear();
                let MM = (dt.getMonth() + 1).toString().padStart(2, "0");
                let dd = dt.getDate().toString().padStart(2, "0");
                return yyyy + "-" + MM + "-" + dd;
            },
            changeJstotal(e,type){
                var reg =  /^(?:[1-9]\d*|0)(?:\.\d+)?$/
                var xz1 = this.detail.JSGMYJ == null || this.detail.JSGMYJ ==  '' || !reg.test(this.detail.JSGMYJ) ? 0 : parseFloat(this.detail.JSGMYJ)
                var xz7 = this.detail.JSGMGS == null || this.detail.JSGMGS ==  '' || !reg.test(this.detail.JSGMGS) ? 0 : parseFloat(this.detail.JSGMGS)
                var xz2 = this.detail.JSGMEJ == null || this.detail.JSGMEJ ==  '' || !reg.test(this.detail.JSGMEJ) ? 0 : parseFloat(this.detail.JSGMEJ)
                var xz3 = this.detail.JSGMSJ == null || this.detail.JSGMSJ ==  '' || !reg.test(this.detail.JSGMSJ) ? 0 : parseFloat(this.detail.JSGMSJ)
                var xz4 = this.detail.JSGMSI == null || this.detail.JSGMSI ==  '' || !reg.test(this.detail.JSGMSI) ? 0 : parseFloat(this.detail.JSGMSI)
                var xz5 = this.detail.JSGMDQ == null || this.detail.JSGMDQ ==  '' || !reg.test(this.detail.JSGMDQ) ? 0 : parseFloat(this.detail.JSGMDQ)/1000
                var xz6 = this.detail.JSGMSD == null || this.detail.JSGMSD ==  '' || !reg.test(this.detail.JSGMSD) ? 0 : parseFloat(this.detail.JSGMSD)/1000

                this.detail.JSGMHJ = this.accAdd(this.accAdd(this.accAdd(this.accAdd(this.accAdd(this.accAdd(xz1,xz2),xz3),xz4),xz5),xz6),xz7)
            },
            accAdd(arg1, arg2) {
                var r1, r2, m;
                try { r1 = arg1.toString().split(".")[1].length; } catch (e) { r1 = 0; }
                try { r2 = arg2.toString().split(".")[1].length; } catch (e) { r2 = 0; }
                m = Math.pow(10, Math.max(r1, r2));
                return (arg1 * m + arg2 * m) / m;
            },
            accDec(arg1, arg2) {
                var r1, r2, m;
                try { r1 = arg1.toString().split(".")[1].length; } catch (e) { r1 = 0; }
                try { r2 = arg2.toString().split(".")[1].length; } catch (e) { r2 = 0; }
                m = Math.pow(10, Math.max(r1, r2));
                return (arg1 * m - arg2 * m) / m;
            },
            //附件处理
            dealFj(data){
                // console.log(data)
                var a = []
                if(data != null && data != undefined && data != ''){
                    data = data.split(';')
                    data.map((subItem)=>{
                        subItem = {url:subItem.substring(0,subItem.lastIndexOf("/") + 1),name:subItem.substring(subItem.lastIndexOf("/") + 1,subItem.length)}
                        a.push(subItem)
                    })
                    return  a
                }else {
                    return  []
                }
            },
            dealSaveFj(data){
                console.log(data);
                if(data == null){
                    return ''
                }
                var pfwjString = []
                data.map((item)=>{
                    pfwjString.push(item.url+item.name)
                })
                // console.log( pfwjString.join(';'),456)
                return pfwjString.join(';')
            },
            save(){
                if(this.buttonLoading == true){
                    return
                }
                this.$refs.detailForm.validate((valid) => {
                    if (valid) {
                        var params = JSON.parse(JSON.stringify(this.detail))
                        params.FillingType = '1'
                        params.jhnd = 2024
                        params.XMGKFJ = this.dealSaveFj(params.XMGKFJ),
                        params.CBSJFJ = this.dealSaveFj(params.CBSJFJ),
                        params.SGHTFJ = this.dealSaveFj(params.SGHTFJ),
                        params.QTFJ = this.dealSaveFj(params.QTFJ),
                        // params.JYJHSQFJ = this.dealSaveFj(params.JYJHSQFJ)
                        params.SGTFJ = this.dealSaveFj(params.SGTFJ)
                        params.SGXKFJ = this.dealSaveFj(params.SGXKFJ)
                        params.SGZBTZSFJ = this.dealSaveFj(params.SGZBTZSFJ)
                        params.ZJAPYJFJ =this.dealSaveFj(params.ZJAPYJFJ)
                        params.DFZFZJCNHFJ =this.dealSaveFj(params.DFZFZJCNHFJ)
                        params.XMDZDTFJ =this.dealSaveFj(params.XMDZDTFJ)
                        // params.newLand = params.newLand == '' ?  null : Number(params.newLand)
                        // params.buildScale = params.buildScale == '' ? null : Number(params.buildScale)
                        // params.SZS = JSON.stringify(params.buildAddress)
                        // params.SZX =  JSON.stringify(params.buildAddress)
                        // if(params.buildAddress && params.buildAddress.length>0){
                        //     params.SZS = params.buildAddress[0]
                        //     params.SZX =  params.buildAddress[1]
                        // }else{
                        //     params.SZS = ""
                        //     params.SZX =  ""
                        // }
                        // params.SSWXMID = this.SSWSSWGHXMID
                        // params.SSWXMMC = this.SSWGHXMMC

                        params.QQID = this.QQID
                        params.QQMC = this.XMMC
                    let regs=new RegExp('号','g')
                    params.QQHZ = this.QQHZXXX.hz+'〔'+this.QQHZXXX.nf+'〕'+(this.QQHZXXX.jw.replace(regs,""))+'号'
                        if(this.QQSGTXXX.hz != ''){
                            params.QQSGT = this.QQSGTXXX.hz+'〔'+this.QQSGTXXX.nf+'〕'+(this.QQSGTXXX.jw.replace(regs,""))+'号'
                        }
                        // params.DWID = this.XMFRDWID;

                        //累计下达计算
                        params.LJXDHJ = '';
                        params.LJXDZYTZ = '';
                        if(this.ljxdtzlist.length>=0 && this.ljxdtzlist){
                            var a = 0;
                            var b = 0;
                            this.ljxdtzlist.map(r=>{
                                a+=this.moneyToNumFiled(r.NJHTZHJ||0);
                                b+=this.moneyToNumFiled(r.NJHTZZYTZ||0);
                            })
                            // console.log(a,b,5);
                            params.LJXDHJ = a;
                            params.LJXDZYTZ = b;
                        }
                        if(params.KGN){
                            let kgn = params.KGN.split("-");
                            if(kgn.length>1){
                                params.KGN = kgn[0]
                            }
                        }
                        if(params.WGN){
                            let wgn = params.WGN.split("-");
                            if(wgn.length>1){
                                params.WGN = wgn[0]
                            }
                        }

                        console.log(params)
                        // return;

                        // let ztz = this.setNumDefault(this.detail.JHZZJZYTZ)+this.setNumDefault(this.detail.JHZZJCZZJ)+this.setNumDefault(this.detail.JHZZJCPYSF)+this.setNumDefault(this.detail.JHZZJZXZQ)+this.setNumDefault(this.detail.JHZZJYHDK)+this.setNumDefault(this.detail.JHZZJSSXZC)+this.setNumDefault(this.detail.JHZZJQYZC)+this.setNumDefault(this.detail.JHZZJDFZC);
                        let ztz =this.multiAdd(this.setNumDefault(this.detail.JHZZJZYTZ),this.setNumDefault(this.detail.JHZZJCZZJ),this.setNumDefault(this.detail.SJBZZJ),this.setNumDefault(this.detail.JHZZJCPYSF),this.setNumDefault(this.detail.JHZZJZXZQ),this.setNumDefault(this.detail.JHZZJYHDK),this.setNumDefault(this.detail.JHZZJSSXZC),this.setNumDefault(this.detail.JHZZJQYZC),this.setNumDefault(this.detail.JHZZJDFZC));

                        // if(ztz != Number(this.detail.JHZZJHJ)){
                        //     console.log(ztz,Number(this.detail.JHZZJHJ))
                        //     this.$message.error("资金明细与资金合计不相等，请仔细核对！");
                        //     return
                        // }
                console.log(params)
                if(!params.QQID){
                            this.$message.error("请选择对应前期项目");
                            return
                        }
// XMGKFJ:"可研批复",
// CBSJFJ:"初步设计批复",
// SGTFJ:"施工图批复",
// ZJAPYJFJ:"资金安排意见",
// DFZFZJCNHFJ:"资金承诺函",
// XMDZDTFJ:"项目地理信息",
// SGHTFJ:"施工合同",
// QTFJ:"其他"
                        var jsxzarr = ['新建','新改建','改扩建','路面改造','其他']
                        if(jsxzarr.indexOf(params.JSXZ) < 0){
                            this.$message.error("建设性质选择有误");
                            return
                        }
                        // if(!params.XMGKFJ){
                        //     this.$message.error("请上传可研批复文件");
                        //     return
                        // }
                        // if(!params.CBSJFJ && !params.SGTFJ){
                        //     this.$message.error("请上传初步设计批复文件");
                        //     return
                        // }
                        // if(!params.SGTFJ){
                        //     this.$message.error("请上传施工图批复文件");
                        //     return
                        // }
                        // // if(!params.ZJAPYJFJ){
                        // //     this.$message.error("请上传资金安排意见文件");
                        // //     return
                        // // }
                        // if(!params.DFZFZJCNHFJ){
                        //     this.$message.error("请上传资金承诺函");
                        //     return
                        // }
                        // if(!params.XMDZDTFJ && params.XMLB != '普通省道'){
                        //     this.$message.error("请上传项目地理信息文件");
                        //     return
                        // }
                        // // if(!params.SGHTFJ){
                        // //     this.$message.error("请上传施工合同文件");
                        // //     return
                        // // }
                        // // if(!params.SGXKFJ){
                        // //     this.$message.error("请上传施工许可文件");
                        // //     return
                        // // }
                        // if( !params.SGZBTZSFJ){
                        //     this.$message.error("请上传施工中标通知书");
                        //     return
                        // }

                        this.buttonLoading = true;

                        if(this.addStatus){
                            params.id='00000000-0000-0000-0000-000000000000'
                            this.http.post('/api/Plan_filling/AddFilling',params).then(res=>{
                                this.buttonLoading = false;
                                if(res.status){
                                    this.showAddModel = false
                                    this.search()
                                    this.$message.success(res.message)
                                    this.$refs.detailForm.resetFields()
                                }else{
                                    this.$message.error(res.message)
                                }
                            })
                        }else{
                            this.buttonLoading = false;
                            this.http.post('/api/Plan_filling/UpdateFilling',params).then(res=>{
                                if(res.status){
                                    this.showAddModel = false
                                    this.$message.success(res.message)
                                    this.search()
                                    this.$refs.detailForm.resetFields()
                                    this.addStatus = true
                                }
                                // if(res.status){
                                //     this.http.post('/api/Plan_filling/SaveFilling?id='+res.data.id,{}).then(res=>{
                                //         if(res.status){
                                //             this.showAddModel = false
                                //             this.$message.success(res.message)
                                //             this.search()
                                //             this.$refs.detailForm.resetFields()
                                //             this.addStatus = true
                                //         }
                                //     })
                                // }else{
                                //     this.$message.error(res.message)
                                // }
                            })
                        }
                    }
                });
            },
            checksB(row){
                this.dialogVisibleid = row.Id
                this.dialogVisible123 = true;
            },
            checksBs(){
                if(this.shsbloading == false){
                    return false;
                }
                this.shsbloading = false;
                this.http.post('/api/Plan_filling/SaveFilling?id='+this.dialogVisibleid,{}).then(res=>{
                    this.shsbloading = true;
                    if(res.status){
                        this.showAddModel = false
                        this.$message.success("上报成功")
                        this.search()
                    }else{
                        this.$message.error(res.message)
                    }
                })
            },
            cancel(){
                this.showMessage = true
                this.showEdit = false
                this.$refs.editForm.resetFields()

            },
            getCaption(obj){
                var index=obj.lastIndexOf(".");
                obj=obj.substring(index+1,obj.length);
                return obj;
            },
            onSubmit(e, type) {
                if (!this.detail[type]){
                    this.detail[type] = []
                }
                console.log( this.detail[type])

                this.uploadLoading = true
                let file = e.target.files;
                let form = new FormData();
                let arrs = [];
                let hz =  this.getCaption(file[0].name)
                if(type != 'XMDZDTFJ'){
                    if (hz != 'pdf' && hz != 'PDF' ){
                        this.$message.warning('请上传pdf格式文件')
                        this.uploadLoading = false
                        return false
                    }
                }else{
                    if (hz != 'zip' && hz != 'ZIP' && hz != 'rar' && hz != 'RAR' ){
                        this.$message.warning('项目地理信息请上传ZIP/RAR格式文件')
                        this.uploadLoading = false
                        return false
                    }
                }

                let aaa = {
                    XMGKFJ:"可研批复",
                    CBSJFJ:"初步设计批复",
                    SGTFJ:"施工图批复",
                    ZJAPYJFJ:"资金安排意见",
                    DFZFZJCNHFJ:"资金承诺函",
                    XMDZDTFJ:"项目地理信息",
                    SGHTFJ:"施工合同",
                    SGXKFJ:"施工许可",
                    SGZBTZSFJ:"施工中标通知书",
                    QTFJ:"其他"
                }
                // console.log(file)
                file.forEach((element) => {
                    form.append("fileInput", element);
                    form.append("fileNames",(this.detail.XMMC?this.detail.XMMC:"")+'-'+aaa[type])
                    arrs.push(element.name);
                });
                this.http.post("/api/Plan_filling/upload", form).then((res) => {
                        this.uploadLoading = false;
                        arrs.forEach((v) => {
                            // console.log(v)
                            console.log(this.detail)
                            this.detail[type].push({ url: res.data, name: v });
                        });
                    });
                // console.log(type)
            },
            openDetailsEdit(row) {
                this.buttonLoading = false;

                // this.isLock = true
                // this.isEdit = true;
                if(this.superVip == false){
                    this.isLock = row.IsShowSave;
                    this.isEdit = row.SFXJ_XMLB=='是'?false:true;
                }

                // if(row.BZ == '二批' && row.IsShowSave){
                //     this.isLock = true;
                // }else{
                //     this.isLock = false
                //
                // }
                // console.log(this.isLock,123)
                // var that = this
                this.showAddModel = true
                this.activeName = '0'
                this.addStatus = false
                this.ljxdtzlist = [];
                this.http.post('/api/Plan_filling/getItemById?id='+row.Id,{}).then(res=>{
                    // res.buildAddress = JSON.parse(res.SZS)
                    res.buildAddress = [res.SZS,res.SZX]
                    this.XMMC = res.QQMC
                    this.QQID = res.QQID
                    console.log(res.QQHZ);
                    console.log(res.QQSGT);

                    let regs=new RegExp('号','g')

    if(res.QQHZ){
        let HQXXXS = res.QQHZ.split('〔');
        if(HQXXXS.length>1){
            let HQXXXS2 = HQXXXS[1].split('〕');
            this.QQHZXXX.hz = HQXXXS[0];
            this.QQHZXXX.nf = HQXXXS2[0];
            this.QQHZXXX.jw = HQXXXS2[1].replace(regs,"");
        }

    }else{
        this.QQHZXXX.hz = [];
        this.QQHZXXX.nf = [];
        this.QQHZXXX.jw =[];
    }

    if(res.QQSGT){
        let HQXXXS2 = res.QQSGT.split('〔');
        if(HQXXXS2.length>1){
            let HQXXXS22 = HQXXXS2[1].split('〕');
            this.QQSGTXXX.hz = HQXXXS2[0];
            this.QQSGTXXX.nf = HQXXXS22[0];
            this.QQSGTXXX.jw = HQXXXS22[1].replace(regs,"");
        }
    }else{
        this.QQSGTXXX.hz = [];
        this.QQSGTXXX.nf = [];
        this.QQSGTXXX.jw = []
    }



                    this.detail = res;
                    // if(this.detail.XMLB == '普通省道'){
                    //     this.ssxzc = true
                    // }else{
                    //     this.ssxzc = false
                    // }
                    // this.detail.JHZZJHJ = this.detail.JHZZJHJ||row.ZTZ
                    // this.detail.JHZZJZYTZ = this.detail.JHZZJZYTZ||row.ZYTZ
                    this.detail.XMGKFJ = this.dealFj(this.detail.XMGKFJ),
                    this.detail.CBSJFJ = this.dealFj(this.detail.CBSJFJ),
                    this.detail.SGHTFJ = this.dealFj(this.detail.SGHTFJ),
                    this.detail.QTFJ = this.dealFj(this.detail.QTFJ),
                    // this.detail.JYJHSQFJ = this.dealFj(this.detail.JYJHSQFJ)
                    this.detail.SGTFJ = this.dealFj(this.detail.SGTFJ)
                    this.detail.SGXKFJ = this.dealFj(this.detail.SGXKFJ)
                    this.detail.SGZBTZSFJ = this.dealFj(this.detail.SGZBTZSFJ)
                    this.detail.ZJAPYJFJ =this.dealFj(this.detail.ZJAPYJFJ)
                    this.detail.DFZFZJCNHFJ =this.dealFj(this.detail.DFZFZJCNHFJ)
                    this.detail.XMDZDTFJ =this.dealFj(this.detail.XMDZDTFJ)
                    if(!this.detail.JHPC){
                        this.detail.JHPC = this.jhpc
                    }
                    if(!this.detail.JHND){
                        this.detail.JHND = this.nowYear
                    }
                    if(!this.detail.SZS){
                        this.detail.SZS = this.bcity.szs
                    }
                    // if(!this.detail.SZX){
                    //     if(this.bcity.szx != this.bcity.szs){
                    //         this.detail.SZX = this.bcity.szx
                    //     }
                    // }

                    console.log(this.detail);
                    this.getItemByQQID([{ID:this.detail.QQID}])
                })
            },
            exportData(type) {
                var that = this
                // /api/Plan_filling/PlanFillingTemplateWrite
                // /api/Plan_filling/Export
                this.loading = true;
                if(type == 1){
                    this.http.post('/api/Plan_filling/PlanFillingTemplateWrite',this.expPostData(type)).then(res=>{
                        this.loading = false;
                        if(!res.status){
                            this.$message.error(res.message)
                            return false
                        }
                        let path = "/api/" + 'Plan_filling' + "/DownLoadFile";
                        path = path[0] == "/" ? path.substring(1) : path;
                        var timestamp = new Date().getTime();
                        // var name = row.XMMC == '' || row.XMMC ==null ? row.SSWGHXMMC :row.XMMC
                        this.download(
                            that.baseUrl + path + "?path=" + res.data,
                            (this.nowYear)+'年投资计划'+timestamp+'.xls'
                        );
                    })
                }else if(type==2){
                    this.http.post('/api/Plan_filling/PlanFillingTemplateWrite',this.expPostData(type)).then(res=>{
                        this.loading = false;
                        if(!res.status){
                            this.$message.error(res.message)
                            return false
                        }
                        let path = "/api/" + 'Plan_filling' + "/DownLoadFile";
                        path = path[0] == "/" ? path.substring(1) : path;
                        var timestamp = new Date().getTime();
                        var names = this.bcity.szx||this.bcity.szs;
                        this.download(
                            that.baseUrl + path + "?path=" + res.data,
                           this.nowYear+'年'+names+this.jhpc+'国省道改造投资计划'+timestamp+'.xls'
                        );
                    })
                }else if(type==3){
                    let c = [];
                    this.expParamsCity.map(r=>{c.push(r[1])})
                    let city = Array.from(new Set(c));

                    var query_SZS = {
                        Name: "SZS",
                        Value: city.join(","),
                        DisplayType: "checkbox",
                    };
                    var query_flowState = {
                        Name: "FlowState",
                        Value: '4',
                        DisplayType: "Equal",
                    };
                    var query_where = [
                        query_SZS,
                        query_flowState
                    ];
                    var postData = {
                        page: this.currentPage,
                        rows: this.pageSize,
                        Sort: "XH",
                        Order: "asc",
                        wheres: JSON.stringify(query_where),
                        value:this.jhpc
                    };
                    this.http.post('/api/Plan_filling/PlanFillingTemplateWriteZIP',postData).then(res=>{
                        this.loading = false;
                        if(!res.status){
                            this.$message.error(res.message)
                            return false
                        }
                        let path = "/api/" + 'Plan_filling' + "/DownLoadFile";
                        path = path[0] == "/" ? path.substring(1) : path;
                        var timestamp = new Date().getTime();
                        this.download(
                            that.baseUrl + path + "?path=" + res.data,
                            (this.nowYear+1)+'年'+this.jhpc+'国省道改造投资计划'+timestamp+'.zip'
                        );
                    })

                    // console.log(city);
                }else{
                    this.loading = false;
                }


                // exportData("/api/Plan_high_national_early/templateWrite", this.postData());
            },
            getRegion() {
                this.expParamsCity = [];
                var postData = { SZDS: "", SZX: "" };
                var newres = [];
                this.http
                    .post("/api/Base_area/getAdminDivTree", postData)
                    .then((res) => {
                        // let citys = [
                        //     {
                        //         value: "",
                        //         label: "北大荒集团",
                        //         children:[
                        //             {
                        //                 value: "",
                        //                 label: "建三江管理局",
                        //             },
                        //         ],
                        //     },
                        //     {
                        //         value: "",
                        //         label: "省建设中心",
                        //         children:[],
                        //     },{
                        //         value: "",
                        //         label: "省公路中心",
                        //         children:[],
                        //     },{
                        //         value: "",
                        //         label: "省交投集团",
                        //         children:[],
                        //     }
                        // ];
                        // res = res.concat(citys)
                        // console.log(res);
                        res.map((r) => {
                            r.value = r.label;
                            newres.push({
                                level: r.level, value: r.label, label:r.label
                            })
                            this.expParamsCity.push(['',r.value])
                            r.children.map((rr) => {
                                rr.value = rr.label;
                            });
                        });
                        this.regions = res;
                        this.regions2[0].children = newres;
                        // console.log(this.regions2);
                    });
            },
            //查询
            search() {
                this.$refs.table.bodyWrapper.scrollTop = 0; //滚动条回顶部
                this.http
                    .post(
                        "/api/Plan_filling/GetData",
                        this.postData(),
                        "正在请求数据，请稍候..."
                    )
                    .then((res) => {
                        this.regions =  [
                            {
                                value: "",
                                label: "黑龙江省",
                            },
                        ]
                        this.getRegion()
                        this.getTJ(1);
                        if (res.status == 0) {
                            this.total = res.total;
                            this.tableData = res.rows;
                        }
                    });
            },
            postDataDs() {
                var szds = "";
                var ssx = "";
                var reg = JSON.parse(JSON.stringify(this.region))
                if (reg.length > 0) {
                    szds = reg[0];
                    if (reg.length > 1) {
                        ssx = reg[1];
                    }
                }

                //项目名称
                var query_xmmc = {
                    Name: "XMMC",
                    Value: this.xmmc,
                    DisplayType: "like",
                };
                //所属市
                var query_szds = {
                    Name: "SZS",
                    Value: szds,
                    DisplayType: "like",
                };
                //所属县
                var query_ssx = {
                    Name: "SZX",
                    Value: ssx,
                    DisplayType: "like",
                };
                //建设性质
                var jsgmArray = [];
                this.checkedJsxz.forEach((element) => {
                    jsgmArray.push(element);
                });
                var query_jsgm = {
                    // Name: "BuildNature",
                    Name: "JSXZ",
                    Value: jsgmArray.join(","),
                    DisplayType: "checkbox",
                };
                //项目类型
                var xmlxArray = [];
                // console.log(this.checkedXmlx);
                this.checkedXmlx.forEach((element) => {
                    xmlxArray.push(element);
                });
                var query_xmlx = {
                    Name: "XLXZ",
                    Value: xmlxArray.join(","),
                    DisplayType: "checkbox",
                };

                var xmlbArray = [];
                // console.log(this.checkedXmlx);
                this.checkedXmlxXMLB.forEach((element) => {
                    xmlbArray.push(element);
                });
                var query_xmLB = {
                    Name: "XMLB",
                    Value: xmlbArray.join(","),
                    DisplayType: "checkbox",
                };

                var query_type = {
                    Name: "FillingType",
                    Value: '1',
                    DisplayType: "Equal",
                };
                var query_Flow = {
                    Name: "FlowState",
                    Value: this.checkedFlow.join(","),
                    DisplayType: "checkbox",
                };
                 var query_flowState = {
                    Name: "FlowState",
                    Value: '3',
                    DisplayType: "Equal",
                };
                var postData = {
                    page: this.currentPage,
                    rows: this.pageSize,
                    Sort: "XH",
                    Order: "asc",
                    wheres: JSON.stringify([
                        // query_xmLB,
                        query_szds,
                        query_Flow,
                        query_ssx,
                        query_xmmc,
                        query_xmlx,
                        query_jsgm,
                        query_type,
                        query_flowState
                    ]),
                };
                return postData;
            },
            postData() {
                var szds = "";
                var ssx = "";
                var reg = JSON.parse(JSON.stringify(this.region))
                if (reg.length > 0) {
                    szds = reg[0];
                    if (reg.length > 1) {
                        ssx = reg[1];
                    }
                }

                //项目名称
                var query_xmmc = {
                    Name: "XMMC",
                    Value: this.xmmc,
                    DisplayType: "like",
                };
                //所属市
                var query_szds = {
                    Name: "SZS",
                    Value: szds,
                    DisplayType: "like",
                };
                //所属县
                var query_ssx = {
                    Name: "SZX",
                    Value: ssx,
                    DisplayType: "like",
                };
                //建设性质
                var jsgmArray = [];
                this.checkedJsxz.forEach((element) => {
                    jsgmArray.push(element);
                });
                var query_jsgm = {
                    // Name: "BuildNature",
                    Name: "JSXZ",
                    Value: jsgmArray.join(","),
                    DisplayType: "checkbox",
                };
                //项目类型
                var xmlxArray = [];
                // console.log(this.checkedXmlx);
                this.checkedXmlx.forEach((element) => {
                    xmlxArray.push(element);
                });
                var query_xmlx = {
                    Name: "XLXZ",
                    Value: xmlxArray.join(","),
                    DisplayType: "checkbox",
                };

                var xmlbArray = [];
                // console.log(this.checkedXmlx);
                this.checkedXmlxXMLB.forEach((element) => {
                    xmlbArray.push(element);
                });
                var query_xmLB = {
                    Name: "XMLB",
                    Value: xmlbArray.join(","),
                    DisplayType: "checkbox",
                };

                var query_type = {
                    Name: "FillingType",
                    Value: '1',
                    DisplayType: "Equal",
                };
                var query_Flow = {
                    Name: "FlowState",
                    Value: this.checkedFlow.join(","),
                    DisplayType: "checkbox",
                };
                var query_Year = {
                    Name: "JHND",
                    Value: this.PCyear[0],
                    DisplayType: "Equal",
                };
                var query_PC= {
                    Name: "JHPC",
                    Value: this.PCyear[1],
                    DisplayType: "Equal",
                };
                var postData = {
                    page: this.currentPage,
                    rows: this.pageSize,
                    Sort: "XH",
                    Order: "asc",
                    wheres: JSON.stringify([
                        query_Year,
                        query_PC,
                        query_xmLB,
                        query_szds,
                        query_Flow,
                        query_ssx,
                        query_xmmc,
                        query_xmlx,
                        query_jsgm,
                        query_type
                    ]),
                };
                return postData;
            },
            expPostData(type) {
                var szds = "";
                var ssx = "";
                var reg = JSON.parse(JSON.stringify(this.region))
                if (reg.length > 0) {
                    szds = reg[0];
                    if (reg.length > 1) {
                        ssx = reg[1];
                    }
                }

                //项目名称
                var query_xmmc = {
                    Name: "XMMC",
                    Value: this.xmmc,
                    DisplayType: "like",
                };
                //所属市
                var query_szds = {
                    Name: "SZS",
                    Value: szds,
                    DisplayType: "like",
                };
                //所属县
                var query_ssx = {
                    Name: "SZX",
                    Value: ssx,
                    DisplayType: "like",
                };
                var query_Year = {
                    Name: "JHND",
                    Value: this.PCyear[0],
                    DisplayType: "Equal",
                };
                var query_PC= {
                    Name: "JHPC",
                    Value: this.PCyear[1],
                    DisplayType: "Equal",
                };
                //建设性质
                var jsgmArray = [];
                this.checkedJsxz.forEach((element) => {
                    jsgmArray.push(element);
                });
                var query_jsgm = {
                    Name: "BuildNature",
                    Value: jsgmArray.join(","),
                    DisplayType: "checkbox",
                };
                //项目类型
                var xmlxArray = [];
                // console.log(this.checkedXmlx);
                this.checkedXmlx.forEach((element) => {
                    xmlxArray.push(element);
                });
                var query_xmlx = {
                    Name: "XLXZ",
                    Value: xmlxArray.join(","),
                    DisplayType: "checkbox",
                };
                var query_type = {
                    Name: "FillingType",
                    Value: '1',
                    DisplayType: "Equal",
                };
                var query_Flow = {
                    Name: "FlowState",
                    Value: this.checkedFlow.join(","),
                    DisplayType: "checkbox",
                };

                var IdArray = [];
                this.multipleSelection.forEach((element) => {
                    IdArray.push(element.Id);
                });
                var query_ID = {
                    Name: "Id",
                    Value: IdArray.join(","),
                    DisplayType: "checkbox",
                };

                if(type == 2 || type == 3){
                    var query_flowState = {
                        Name: "FlowState",
                        Value: '4',
                        DisplayType: "Equal",
                    };
                }

                var query_where = [
                    query_szds,
                    query_Flow,
                    query_ssx,
                    query_xmmc,
                    query_xmlx,
                    query_jsgm,
                    query_type,
                    query_Year,
                    query_PC
                ];
                if(type == 1){
                    query_where.push(query_ID)
                }
                if(type == 2 || type == 3){
                    query_where.push(query_flowState)
                }
                // console.log(query_where);
                var postData = {
                    page: this.currentPage,
                    rows: this.pageSize,
                    Sort: "XH",
                    Order: "asc",
                    wheres: JSON.stringify(query_where),
                    value:this.jhpc
                };

                return postData;
            },
            clearCondition() {
                this.region = [""];
                this.xmmc = "";
                this.frdw = "";
                this.xmnd = "";
                this.qqgzjz = [];
                this.gllx = "";
                this.checkedJsxz= [];
                this.checkedFlow= [];
                this.checkedXmlb = [];
                this.checkedJsgm = []
                this.checkedGk = [];
                this.checkedCbsj = [];
                this.checkedSgtsj = [];
                this.checkedXmlx = [];
            },
            handleSizeChange(val) {
                this.pageSize = val;
                this.search();
            },
            handleCurrentChange(val) {
                this.currentPage = val;
                this.search();
            },
            handleSizeChange1(val) {
                this.pageSize1 = val;
                this.getCompanyList()
            },
            handleCurrentChange1(val) {
                this.currentPage1 = val;
                this.getCompanyList()

            },
            setNumDefault(nums){
                if(!nums) return 0;
                return parseFloat(nums);
            },
            moneyToNumFiled(money){
                if(money == 0 || !money) return 0;
                return money;

                // var num=money.trim();
                // var ss=num.toString();
                // if(ss.length==0){
                //     return 0.00;
                // }
                // num=new Number(ss.replace(/,/g, ""));
                // return parseFloat(num);
            },
        },
        watch: {
            detail: {
                // 数据变化回调方法
                handler(val) {
                    console.log(val)
                    // console.log(this.setNumDefault(val.JHZZJCPYSF));
                    // if(!this.detail.id){
                    //     this.detail.JHZZJHJ = (this.setNumDefault(val.JHZZJZYTZ)+this.setNumDefault(val.JHZZJCZZJ)+this.setNumDefault(val.JHZZJCPYSF)+this.setNumDefault(val.JHZZJZXZQ)+this.setNumDefault(val.JHZZJYHDK)+this.setNumDefault(val.JHZZJQYZC)+this.setNumDefault(val.JHZZJDFZC))||"";
                    // }
                    // this.detail.LJWCTZHJ = (this.setNumDefault(val.LJWCTZZYTZ)+this.setNumDefault(val.LJXDCZZJ)+this.setNumDefault(val.LJXDCPYSF)+this.setNumDefault(val.LJWCTZZXZQ)+this.setNumDefault(val.LJWCTZYHDK)+this.setNumDefault(val.LJWCTZQYZC)+this.setNumDefault(val.LJXDDFZC))||"";
                    this.detail.NJHTZHJ = (this.setNumDefault(val.NJHTZZYTZ)+this.setNumDefault(val.NJHTZZYTZ2)+this.setNumDefault(val.NJHTZSJBZZJ)+this.setNumDefault(val.NJHTZCZZJ)+this.setNumDefault(val.NJHTZCPYSF)+this.setNumDefault(val.NJHTZZXZQ)+this.setNumDefault(val.NJHTZYHDK)+this.setNumDefault(val.NJHTZSSXZC)+this.setNumDefault(val.NJHTZZQYZC)+this.setNumDefault(val.NJHTZDFZC)).toFixed(2)||"";
                    // console.log(this.ljxdtzlist);
                    if(this.ljxdtzlist.length<=0 || !this.ljxdtzlist){
                        this.detail.SYZJHJ =this.minus(this.detail.JHZZJHJ ? this.detail.JHZZJHJ  : 0,this.setNumDefault(this.detail.NJHTZHJ));
                        this.detail.SYZJZYTZ = this.minus(this.minus(val.JHZZJZYTZ  ? val.JHZZJZYTZ : 0,this.setNumDefault(val.NJHTZZYTZ)),this.setNumDefault(val.NJHTZZYTZ2));
                        this.detail.SYZJCZZJ = this.minus(val.JHZZJCZZJ ? val.JHZZJCZZJ : 0,this.setNumDefault(val.NJHTZCZZJ));
                        this.detail.SYZJCPYSF = this.minus(val.JHZZJCPYSF ? val.JHZZJCPYSF : 0,this.setNumDefault(val.NJHTZCPYSF));
                        this.detail.SYZJZXZQ = this.minus(val.JHZZJZXZQ ? val.JHZZJZXZQ : 0,this.setNumDefault(val.NJHTZZXZQ));
                        this.detail.SYZJYHDK = this.minus(val.JHZZJYHDK ? val.JHZZJYHDK : 0,this.setNumDefault(val.NJHTZYHDK));
                        this.detail.SYZJSSXZC = this.minus(val.JHZZJSSXZC ? val.JHZZJSSXZC : 0,this.setNumDefault(val.NJHTZSSXZC));
                        this.detail.SYZJZQYZC = this.minus(val.JHZZJQYZC ? val.JHZZJQYZC : 0,this.setNumDefault(val.NJHTZZQYZC));
                        this.detail.SYZJDFZC = this.minus(val.JHZZJDFZC ? val.JHZZJDFZC : 0,this.setNumDefault(val.NJHTZDFZC));
                        this.detail.SYZJSJBZZJ = this.minus(val.SJBZZJ ? val.SJBZZJ : 0,this.setNumDefault(val.NJHTZSJBZZJ));
                    }else{
                        this.setZjValue(this.ljxdtzlist)
                    }
                },
                // 发生变化了，立即调用回调方法
                immediate: true,
                // 监听对象里的字段变化？
                deep: true
            },
            activeIndex(v) {
                if (v) {
                    this.tableHeight = document.body.offsetHeight - 450;
                } else {
                    this.tableHeight = document.body.offsetHeight - 245;
                }
            },
        },
    };
</script>
<style>
    .hidden {
        display: none ;
    }
</style>
<style lang="less" scoped>
.littleTable{
    /deep/input[readonly] {
        background-color: #fff !important;
        border: 0px solid #ddd !important;
    }
}
.editWrap{

 /deep/.el-drawer__body {
    padding: 12px 15px 0px;
    /* padding-bottom: 60px; */
}

}
    /deep/ .el-textarea{
        .el-textarea__inner{
            height: auto !important;
            min-height: auto !important;
        }
    }
    .roadFront{
        font-family: "Microsoft YaHei 微软雅黑";
    }
    /deep/.el-step__title.is-process{
        color: #409EFF !important;
        border-color: #409EFF !important;
    }
    /deep/.el-collapse-item__header{
        height: 32px;
        padding:0 15px;
        background: #F2F8FE !important;
    }
    .title{
        padding: 0 16px;
        min-width: 98px;
        height: 32px;
        background: #409EFF;
        opacity: 1;
        line-height: 32px;
        text-align: center;
        color: #fff;
    }
    .file{
        margin-right: 10px;
        display: inline-block;
        margin-top: 5px;
        padding: 0 6px;
        height: 32px;
        background: #FFFFFF;
        border: 1px solid #B3D8FF;
        opacity: 1;
        border-radius: 4px;
        color: #409EFF;
        a{
            height: 32px;
            line-height: 32px;
        }
    }

    .roadFront{
        .jhky{
            /deep/ .el-form-item{
                margin-right: 0px !important;
            }
        }
        .ty{
            /deep/ .el-form-item{
                margin-right: 0px !important;
            }
        }
        /deep/.el-collapse{
            margin-top: 20px;
        }
        /deep/.el-form--inline .el-form-item{
            margin-right: 20px;
        }
        /deep/.el-collapse-item__content {
            padding:10px 15px 20px 15px  !important;
        }
        .editModal{
            /deep/.el-input__inner{
                width: 330px;
            }
            .tz{
                /deep/.el-input__inner{
                    width: 280px;
                }
                /deep/.el-form--inline .el-form-item{
                    margin-right: 0px;
                }
            }
            .jhky{
                /deep/.el-input__inner{
                    width: 135px;
                }
            }
            .ty{
                /deep/.el-input__inner{
                    width: 270px;
                }
            }
            .tztop{
                /deep/.el-form-item__label{
                    background: unset;
                }
                /deep/.el-input__inner {
                    width: 260px;
                }
            }
            .littleTable{
                /deep/.el-input__inner {
                    width: 270px;
                    border: none;
                    height: 45px;
                }
            }
            .littleTable2{
                /deep/.el-input__inner {
                    width: 142px;
                    /*margin: 2px 4px;*/
                    border: none;
                    height: 45px;
                }
            }
        }
        .dwmcWrap{
            /deep/.el-input__inner{
                width: 330px;
            }

        }
        /deep/.box-content .el-form-item__label{
            margin-right: 0px;
            text-align: left;
            color: #034761;
            background:unset;
        }
        /deep/ .formBox .el-input--suffix{
            width: 160px;
        }
        /deep/.el-form-item__label{
            margin-right: 4px;
            color: #000;
            text-align: center;
            background: #f2f8fe;
        }
        .search{
            /deep/.el-form-item__label{
                background: unset;
            }
        }
        .col{
            /deep/.el-form-item__label{
                background: #F2F8FE;
                color: #78818E;
                line-height: 30px;
                float: unset;
            }
        }
    }

    .footer{
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 47px;
        background: #F8F8F8;
        opacity: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    /*/deep/ .el-input--suffix{*/
    /*    width: 330px;*/
    /*}*/
    .editWrap{
        /deep/.el-drawer__body{
            padding: 12px 15px 0px;
            /*padding-bottom: 60px;*/
        }
        .el-row{
            margin-bottom: 4px;
        }
    }

    /deep/.el-form-item__error {
        position: relative !important;
        top: auto;
        display: block;
    }
    /deep/.el-step.is-simple:not(:last-of-type) .el-step__title{
        max-width: 70%;
    }
    /*/deep/.el-form-item__error--inline{*/
    /*    top: 14px;*/
    /*    left: 5px;*/
    /*    position: absolute;*/
    /*    background: #fff;*/
    /*    display: inline-block;*/
    /*    margin-left: 10px;*/
    /*}*/
    .bigTitle{
        width: 50%;
        margin: 24px 0;
        /*border-bottom: 1px solid #B3D8FF;*/
        span{
            color: #333;
            font-weight: 600;
            padding: 8px 12px;
            height: 28px;
            background: #B3D8FF;
            opacity: 1;
            border-radius: 0px 14px 0px 0px;
        }

    }
    a{
        border: none;
    }
    .dwmcWrap{
        cursor: pointer;
        width: 330px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border:1px solid #DCDFE6;
        border-radius: 4px;
        .dwmc{
            padding-left: 10px;
            text-align: left;
            width: 330px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            height: 40px;
            background: #ffffff;
            border-radius: 4px 0 0 4px;
        }
        .plus{
            border-left: 1px solid #f9f9f9;
            width: 20px;
            height: 40px;
            background: #ffffff;
            border-radius: 0px 4px 4px 0;
            i{
                font-size: 16px;
            }
        }
    }
    // 隐藏全选框
    /deep/ .DisableSelection > .cell {
        display: none !important;
    }
    /deep/.el-tabs--card>.el-tabs__header .el-tabs__item.is-active{
        color: #fff;
        background: #409eff;
    }

    .fl-jus {
        margin-bottom: 4px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .label {
        margin-right: 8px;
        width: 126px;
        height: 42px;
        background: #fafafa;
        line-height: 42px;
        color: #000000;
        font-weight: 600;
        text-align: center;
    }
    .label2 {
        height: 47px;
        background: #F5F7FA;
        line-height: 42px;
        color: #000000;
        font-weight: 600;
        text-align: center;
    }
    .label3 {
        height: 47px;
        background: #fafafa;
        line-height: 42px;
        color: #000000;
        font-weight: 600;
        text-align: center;
    }
    .cont {
        width: 220px;
        height: 47px;
        text-align: center;
        line-height: 36px;
    }
    .cont2{
        height: 47px;
        text-align: center;
        line-height: 36px;
    }
    table {
        border-collapse: collapse;
    }
    td {
        border: 1px solid #dddddd;
        .cont {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .flexs-m {
        display: flex;
        align-items: initial;
    }
        .title {
            margin-top: 20px;
            background: #f2f8fe;
            display: flex;
            align-content: center;
            justify-content: space-between;
            cursor: pointer;
            width: 100%;
            i {
                line-height: 32px;
                margin-right: 12px;
                font-size: 18px;
            }
            span {
                display: block;
                width: 98px;
                height: 32px;
                background: #409eff;
                line-height: 32px;
                font-size: 16px;
                font-family: Segoe UI;
                color: #ffffff;
                font-weight: 600;
                text-align: center;
            }
        }

            .name {
                width: 220px;
                height: 42px;
                background: #fafafa;
                text-align: center;
                line-height: 42px;
                font-weight: 600;
            }
            .value {
                display: flex;
                align-items: center;
                width: calc(100% - 168px);
                padding: 0 10px;
                // height: 42px;
                line-height: 42px;
                font-weight: 400;
                box-sizing: border-box;
                color: #666666;
            }
            .name1 {
                width: 74px;
                line-height: normal;
                label {
                    width: 100%;
                    padding: 0;
                    height: 32px;
                    line-height: 32px;
                    font-size: 14px;
                    font-family: Segoe UI;
                    font-weight: 400;
                    color: #ffffff;
                    i {
                        font-style: normal !important;
                    }
                }
            }
            .fileWrap{
                display: flex;
                align-items: center;
                flex-wrap: wrap;
            }
            .value1 {
                display: flex;
                align-items: center;
                width: calc(100% - 168px);
                display: flex;
                height: auto;
                label {
                    display: inline-block;
                    vertical-align: baseline;
                }
                .files {
                    display: block;
                    // border: 1px solid #b3d8ff;
                    // height: 36px;
                    // line-height: 36px;
                    border-radius: 4px;
                    position: relative;
                    top: 2px;
                    .wj {
                        margin-bottom: 5px;
                        border: 1px solid #b3d8ff;
                        height: 30px;
                        vertical-align: middle;
                        line-height: 30px;
                        display: inline-block;
                        margin-left: 10px;
                        border-radius: 4px;
                        padding: 0 5px;
                        position: relative;
                        top: -2px;
                    }
                }
            }
            .name3 {
                background: none;
            }
    .file{
        margin-right: 10px;
        display:flex;
        align-items: center;
        margin-top: 0px;
        padding: 0 6px;
        height: 32px;
        background: #FFFFFF;
        border: 1px solid #B3D8FF;
        opacity: 1;
        border-radius: 4px;
        color: #409EFF;
        a{
            height: 32px;
            line-height: 32px;
        }
    }
    .col{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 60px;
        .col-1{
            padding:10px 20px 20px;
            width: 330px;
            background: #F2F8FE;
            min-height: 780px;
            border-radius: 5px;
        }
    }
    .deliver{
        margin: 15px 0;
        width: 100%;
        height: 1px;
        border-bottom: 1px dotted #999999;
    }
    .el-divider__text{
        background: #F2F8FE;
    }
    .newform{
       /deep/.el-input__inner{
                width: 100% !important;
                padding: 0 5px !important;
        }
        /deep/.el-form-item__error{
                position: absolute !important;
    top: -2px;
        }
        /deep/.el-select{

            /deep/.el-input--suffix{
                width: 100% !important;
                /deep/.el-input__inner{
                    text-align: right;
                    // color: #C0C4CC;
                    font-size: 14px;
                    padding-right: 30px !important;

                }

            }
            /deep/.is-disabled{

                    /deep/.el-input__inner{
                    background-color: #F5F7FA !important;

                }
                }
        }
    }
    .newre{
        display: inline-block;
        background: #409eff;
        padding: 0px 5px;
        border-radius: 50px;
        margin: 0 9px;
        position: relative;
        top: 1px;
        /deep/.el-input__suffix{
            right: 9px;
            top: -1px;
        }
        /deep/.el-tag{
            background: none !important;
        }
         /deep/.el-tag:not(:first-child){
            display: none;
        }
        /deep/.el-button--mini{
            position: relative;
            top: -1px;
            padding: 3px 0 !important;
        }
        /deep/.el-input__inner{
            width: 120px !important;
            height: 20px !important;
            border-radius: 50px;
        }
        /deep/.el-input--suffix{
            width: 100% !important;
            .el-input__icon{
                    line-height: 30px;
            }
        }
    }
    .colors{
        /deep/.el-input__inner{
            color: #f00;
        }
    }
    /deep/input [type="number"]{
        -moz-appearance:textfield;
    }
    /deep/input::-webkit-outer-spin-button{
        -webkit-appearance: none !important;
    }
    /deep/input::-webkit-inner-spin-button{
        -webkit-appearance: none !important;
    }
    .inputcenter{
        /deep/.el-input__inner{
            text-align: center;
        }
    }
    .inputright{
        /deep/.el-input__inner{
            text-align: right;
        }
    }
    /deep/.is-disabled{
        .el-input__inner{
                background-color: #F5F7FA !important;
        }

    }
    .dis{
        background-color: #F5F7FA !important;
    }
    .xxh{
        display: inline-block;
        width: 26.5%;
        /deep/.el-input__inner{

        border: 0;
    border-bottom: 1px #dcdfe6 solid;
            width: 100% !important;
        }
    }
.fale{
    padding:3px 5px;
    background: #F5F7FA;
    color: #c0c4cc;text-align: right
}
</style>
